import React,{useState, useEffect,useRef} from 'react' 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux'; 
import useTheme from '../../Components/Theme/useTheme'
import CDiv from '../../Components/CDiv/CDiv'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel  from '../../Components/Carousel/Carousel';
import Title from '../../Components/Title/Title';
import Header from '../../Components/Header/Header';
import useWindowDimensions from '../../Components/useWindowDimensions';
import Button from '../../Components/Button/Button';
import Signup from '../../Components/Signup/Signup';
import SignupSelector from './SignupSelector';
import Text from '../../Components/Text/Text';
import {GiSolarPower} from 'react-icons/gi'
import {RiMentalHealthLine} from 'react-icons/ri'
import {MdSecurity} from 'react-icons/md'
const Other = (props) => {
    const Theme = useTheme();  
    const [selectedFiber,setSelectedFiber] =  useState(false)
    const [selectedCable,setSelectedCable] =  useState(false)
    const [hideBack,setHideBack] = useState(false)
    const [selectedPhone,setSelectedPhone] =  useState(false)
    const [employees,setEmployees] = useState(null)
    const [user,setUser] = useState({name:'Sterling Group',email:Theme.main.siteEmail})
    const [firstLoad, setFirstLoad] = useState(false) 
    const {height,width} = useWindowDimensions()
    const scroll1 = useRef() 
    const scroll2 = useRef()
    const scroll3 = useRef()
    const scroll4 = useRef()
    useEffect(()=>{
        if(!firstLoad){
            setFirstLoad(true)
            props.setCurrentName("Other Products")
        }
    },[props.data])
    const scrollOne = (index) =>{
        setTimeout(()=>{
            switch(index){
                case 0:
                    scroll1.current.scrollIntoView({behavior:'smooth'})
                break;
                case 1:
                    scroll2.current.scrollIntoView({behavior:'smooth'})
                break;
                case 2:
                    scroll3.current.scrollIntoView({behavior:'smooth'})
                break;
                case 3:
                    scroll4.current.scrollIntoView({behavior:'smooth'})
                break;
        }
        },500)
       
    }
    return (
        <CDiv style={{backgroundImage:Theme.main.backgroundImage,backgroundRepeat:'no-repeat',backgroundPosition:'center'}}> 
        <Carousel fourButton={true} buttonText="Contact"   scroller={(index)=>scrollOne(index)}   width={'100vw'}  height={props.deviceType === "mobile" ? '88vh' :'100vh'}  items={[{name:'Solar',
                                                            button:'Solar',
                                                            img:'https://images.pexels.com/photos/371917/pexels-photo-371917.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                                                            intro:'Are you Missing Out on Solar Power?',
                                                            rest:'Save money, go green. Everyone wins.'},
                                                            {name:'Security',

                                                            button:'Security',
                                                            img:'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                                                            intro:'Our Security Services',
                                                            rest:'People protect their homes at two different times in their life 1. Within 30 days of moving into a new home and 2. After something happens. Our job is to keep you out of that second category. Protect your family today'},
                                                            {name:'Wellness',
                                                            button:'Wellness',
                                                            img:'https://images.pexels.com/photos/37351/silhouette-aerialist-female-woman.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                                                            intro:'Feel Better Live Better, Melaleuca',
                                                            rest:'HEALTHY ENVIROMENT plant based products for a healthy home and safe for the environment. POWERFUL these products are clinically tested to provide super cleaning products, that is affordable for everyone. Join in the Wellness for a safe clean home'}]}/>
          <div ref={scroll1} style={{minHeight:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:'center',flexFlow:'column'}} >   
          
          <GiSolarPower style={{fontSize:'100px' ,padding:'0px',marginTop:'10vh',transitionDuration:"500ms",color:Theme.main.colorTertiary}}/>
         <Header style={{color:Theme.main.colorTertiary,marginTop:'0vh',marginBottom:'4vh'}}>Solar</Header>
     
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"10vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',overflow:"hidden",flexFlow:"column"  ,justifyContent:'center',alignItems:"center",padding:"25px"}}>
            <iframe   style={{borderRadius:'6px',width:width<(Theme.main.breakPoint + 200) ? '95vw':'45vw',height:width<(Theme.main.breakPoint + 200) ? '55vw':'25vw',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)',marginTop:'0vh',marginBottom:'3vh'}}    src={props.data && props.data.other && props.data.other.product1 ? props.data.other.product1.url :null} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> 
            {width < Theme.main.breakPoint+200 ? <hr style={{width:'86%',height:'1px',marginBottom:'5vh',backgroundColor:Theme.main.colorTertiary,border:"1px solid "+Theme.main.colorTertiary}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:Theme.main.colorTertiary,border:"1px solid "+Theme.main.colorTertiary}}></hr>} 
            
            <div style={{display:'flex',flexFlow:"column",padding:"15px",width:width<(Theme.main.breakPoint + 200)? '85%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
            <Text>{props.data && props.data.other && props.data.other.product1 ? props.data.other.product1.text :null}</Text> 
            </div>  
        </div>  
        </div>  
        <div ref={scroll2}  style={{minHeight:"100vh",width:"100%",backgroundColor:Theme.main.colorTertiary,display:"flex",justifyContent:"center",alignItems:'center',flexFlow:'column'}} > 
        <MdSecurity style={{fontSize:'100px' ,padding:'0px',marginTop:'8vh',transitionDuration:"500ms",color:'white'}}/>
        <Header style={{color:'white',marginTop:'0vh',marginBottom:'0vh'}}>Security</Header>
        <div style={{border:"solid 1px rgba(255,255,255,1.0)",borderRadius:"2px",marginBottom:'14vh',marginTop:"0vh",padding:'40px'}}>
        <Title style={{color:'white',fontWeight:"550",width:'100%',textAlign:'center',opacity:0.9}}>Double 0 Enterprises</Title> 
             <Text style={{color:'white',fontWeight:"550",width:'100%',marginTop:"1vh",textAlign:'center',opacity:0.9}}>YVETTE SKELNIK</Text> 
             <Text style={{color:'white',fontWeight:"550",width:'100%',marginTop:"0vh",textAlign:'center',opacity:0.9}}>ADT AUTHORIZED DEALER</Text> 
             <Text style={{color:'white',fontWeight:"550",width:'100%',marginTop:"0vh",textAlign:'center',opacity:0.9}}>1-630-935-1420</Text> 
        </div>
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"5vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',width:width<Theme.main.breakPoint + 200 ? '95%':'45%',height:'auto',flexFlow:"column" ,justifyContent:'center',alignItems:"center"}}>
                <img src={props.data && props.data.other && props.data.other.product2 ? props.data.other.product2.image1 :null} style={{width:"90%",height:"auto",borderRadius:'6px',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} />
                  </div>  
                  {width < Theme.main.breakPoint+200 ? <hr style={{width:'90%',height:'0px',marginTop:'10vh',backgroundColor:'white',border:"1px solid white"}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:'white',border:"1px solid white"}}></hr>} 
            <div style={{display:'flex',flexFlow:"column",margin:'0px',padding:"0px",width:width<Theme.main.breakPoint + 200 ? '85%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
            <Title style={{color:'white',fontWeight:"550",width:'100%',textAlign:'center',opacity:0.9,marginTop:'4vh'}}>{props.data && props.data.other && props.data.other.product2 ? props.data.other.product2.header1 :null}</Title> 
            <Text style={{color:'white',fontWeight:"550",width:'100%',textAlign:'center',opacity:0.9,marginTop:'8vh',marginBottom:'0vh'}}>{props.data && props.data.other && props.data.other.product2 ? props.data.other.product2.title1 :null}</Text> 
         
          
            </div>  
        </div> 
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"10vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',flexFlow:"column",margin:'0px',padding:"0px",width:width<Theme.main.breakPoint + 200 ? '100%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
            <Title style={{color:'white',fontWeight:"550",width:'auto',marginBottom:'4vh',textAlign:'center',opacity:0.9}}>{props.data && props.data.other && props.data.other.product2 ? props.data.other.product2.header2 :null}</Title> 
            </div>   
            {width < Theme.main.breakPoint+200 ? <hr style={{width:'90%',height:'0px',marginBottom:'10vh',backgroundColor:'white',border:"1px solid white"}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:'white',border:"1px solid white"}}></hr>} 
            <div style={{display:'flex',width:width<Theme.main.breakPoint + 200 ? '95%':'45%',height:'auto',flexFlow:"column" ,justifyContent:'center',alignItems:"center"}}>
                 <img src={props.data && props.data.other && props.data.other.product2 ? props.data.other.product2.image2 :null} style={{width:"90%",boxShadow:'0px 0px 20px rgba(0,0,0,0.9)',height:"auto",borderRadius:'6px'}}  />
             </div>  
        </div> 
        </div>  
        <div ref={scroll3}  style={{minHeight:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:'center',flexFlow:'column'}} > 
        <RiMentalHealthLine style={{fontSize:'100px' ,padding:'0px',marginTop:'8vh',transitionDuration:"500ms",color:Theme.main.colorTertiary}}/>
        <Header  style={{color:Theme.main.colorTertiary,marginTop:'0vh',marginBottom:'0vh'}}>Wellness</Header>
        
        <Button styler={{borderRadius:'2px',border:"none",boxShadow:"0px 0px 20px rgba(0,0,0,0.9)",fontWeight:"900",fontSize:'32px',height:"auto",width:"auto",padding:"45px",backgroundColor:Theme.main.colorTertiary,color:'white',marginBottom:'8vh',marginTop:"4vh"}} click={()=>{window.open('https://www.melaleuca.com/productstore')}}   style={{color:'black',fontWeight:"550",width:'100%',marginBottom:'2vh',textAlign:'center',opacity:0.9}}>Melaleuca</Button>   
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"5vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',width:width<Theme.main.breakPoint + 200 ? '95%':'45%',height:'auto',flexFlow:"column" ,justifyContent:'center',alignItems:"center"}}>
                <img src={props.data && props.data.other && props.data.other.product3 ? props.data.other.product3.image1 :null} style={{width:"90%",height:"auto",borderRadius:'6px',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} />
                  </div>  
                  {width < Theme.main.breakPoint+200 ? <hr style={{width:'90%',height:'0px',marginTop:'10vh',backgroundColor:'black',border:"1px solid black"}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:'black',border:"1px solid black"}}></hr>} 
            <div style={{display:'flex',flexFlow:"column",margin:'0px',padding:"0px",width:width<Theme.main.breakPoint + 200 ? '85%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
            <Title style={{color:'black',fontWeight:"550",width:'100%',textAlign:'center',opacity:0.9,marginTop:'4vh'}}>{props.data && props.data.other && props.data.other.product3 ? props.data.other.product3.header1 :null}</Title> 
            <Text style={{color:'black',fontWeight:"550",width:'100%',textAlign:'center',opacity:0.9,marginTop:'4vh',marginBottom:'0vh'}}> {props.data && props.data.other && props.data.other.product3 ? props.data.other.product3.title1 :null}</Text> 
            </div>  
        </div> 
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"10vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',flexFlow:"column",margin:'0px',padding:"0px",width:width<Theme.main.breakPoint + 200 ? '100%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
            <Title style={{color:'black',fontWeight:"550",width:'auto',marginBottom:'4vh',textAlign:'center',opacity:0.9}}>{props.data && props.data.other && props.data.other.product3 ? props.data.other.product3.header2 :null}</Title> 
            </div>   
            {width < Theme.main.breakPoint+200 ? <hr style={{width:'90%',height:'0px',marginBottom:'10vh',backgroundColor:'black',border:"1px solid black"}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:'black',border:"1px solid black"}}></hr>} 
            <div style={{display:'flex',width:width<Theme.main.breakPoint + 200 ? '95%':'45%',height:'auto',flexFlow:"column" ,justifyContent:'center',alignItems:"center"}}>
                 <img src={props.data && props.data.other && props.data.other.product3 ? props.data.other.product3.image2 :null} style={{width:"90%",boxShadow:'0px 0px 20px rgba(0,0,0,0.9)',height:"auto",borderRadius:'6px'}}  />
             </div>  
        </div> 
        </div>  
        <div ref={scroll4}  style={{minHeight:"100vh",overflow:"hidden" ,width:"100%",backgroundColor:Theme.main.colorTertiary,display:"flex",justifyContent:"center",position:'relative',flexFlow:'column' }} > 
        <Header style={{color:'white',marginTop:'5vh',marginBottom:'-2vh'}}>Solar  Security  Wellness</Header>  
        <Title style={{color:"white",marginLeft:"5px",marginRight:'5px',padding:'0px',textAlign:'center'}}>Please select the services you are interested in.</Title>
        
        <div style={{position:"relative",display:'flex',flexFlow:'column',margin:'auto',top:user ?'-0px':'0px'}}> 
        <Button click={(selectedFiber || selectedCable || selectedPhone)  ? ()=>{props.setModalContent(<Signup items={[]} type="OTHER" itemsother={[selectedFiber,selectedCable,selectedPhone]}/>);props.setShowModal(true);}:()=>{}} styler={{opacity:(selectedFiber || selectedCable || selectedPhone) && user?1:0,backgroundColor:"white",width:"250px",fontWeight:'900',border:'none',minHeight:'auto',height:"auto",minWidth:"250px",margin:'auto',marginTop:"25px",boxShadow:'0px 0px 20px white',borderRadius:'0px',padding:'20px',transitionDuration:"1s"}}>Contact Us</Button>  
       
        </div>  
          <div style={{width:"100%",marginBottom:"0vh",overflow:"hidden",transform:  (selectedFiber || selectedCable || selectedPhone) ? 'translateY(30px)' :'translateY(-4vh)' ,height:"auto",minHeight:'70vh',overflowY:'hidden',transitionDuration:'1s',position:'relative',display:"flex",marginTop:'0px',justifyContent:"center",alignItems:'center',flexWrap:'wrap'}}>
           
            <SignupSelector selected={selectedFiber} setSelected={(e)=>{setSelectedFiber(!selectedFiber); }}  type={0}/>
            <SignupSelector selected={selectedCable} setSelected={(e)=>{setSelectedCable(!selectedCable); }}  type={1}/>
            <SignupSelector  selected={selectedPhone} setSelected={(e)=>{setSelectedPhone(!selectedPhone); }}  type={2}/>

        </div> 
            
        </div> 
       </CDiv> 
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state) }
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch) }
}
export default connect(mapStateTopProps,mapDispatchToProps)(Other) 

import logo from './logo.svg';
import React from 'react'
import './App.css';
import firebase from 'firebase/app'
import {BrowserRouter} from 'react-router-dom'; 
import Main from './Main'
import {combineReducers, createStore} from 'redux'
import {Provider} from 'react-redux'
import toolbarR from './Store/Reducers/toolbarR'   
import { ParallaxProvider } from 'react-scroll-parallax';
var firebaseConfig = {
  apiKey: "AIzaSyBy-R7XqOV7jRrCaL4B4lIt-od-p2d7M2E",
  authDomain: "sterlinggroupsite.firebaseapp.com",
  projectId: "sterlinggroupsite",
  storageBucket: "sterlinggroupsite.appspot.com",
  messagingSenderId: "908777503585",
  appId: "1:908777503585:web:9d015eed442e8cd5f5e320",
  measurementId: "G-GMGRMDDP49"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
//this will be inputed into all componenets, accessible via a "props.db() command"
// ie props.db().collection("NameHere").doc(**can be blank).set({varName:varValue,etc});
const db = firebase.firestore();
//combinding reducers
const rootReducer = combineReducers({
  toolbar:toolbarR
})
//creating redux store
const store = createStore(rootReducer);
function App() { 
  // const stripePromise = loadStripe('pk_test_6W6ghbBMJq94F93e1jjLycTZ00aBYGR1ny',{stripeAccount:'acct_1F1ibMKjcTFZWu0B'})
  return ( <BrowserRouter>  
      <Provider store={store}>  
        <ParallaxProvider>
          
          <Main/>   
            
        </ParallaxProvider>
      </Provider> 
     </BrowserRouter> 
  );
}

export default App;

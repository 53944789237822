import React from 'react'
import useTheme from '../Theme/useTheme'
import ButtonStrap from 'react-bootstrap/Button'
const Button = (props) => { 
    const Theme = useTheme();
    return ( 
            <ButtonStrap onClick={(e)=>{props.click?props.click(e):console.log("No click")}} style={props.styler !== null ?{...Theme.button,...props.styler} : {...Theme.button}}>
                {props.children} 
            </ButtonStrap>
    )
} 
export default Button

import React , {useEffect,useState} from 'react'  
import useTheme from '../Theme/useTheme'
import {AiFillCloseCircle} from 'react-icons/ai'
import classes from './Toolbar.module.css'
import useWindowDimensions from '../useWindowDimensions'  
import { connect } from 'react-redux'; 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import Header from '../Header/Header'
import Title from '../Title/Title'
const SideDrawer = (props) => {
    // console.log("SideDrawer.js" + JSON.stringify(props))
    const Theme = useTheme();
    const {height, width} = useWindowDimensions() 
    useEffect(() => {
        if(props.navigationShow && props.showMenu){
            props.setNavigation(false)
        }
    //    return()=>{setLoading(true)} 
    }, [props.showMenu,props.auth,props.navigationShow])
    return (
        <div className={props.showMenu && width <= Theme.main.breakPoint ? classes.drawerActive : classes.drawerClosed} style={{position:'fixed',overflowX:'hidden',zIndex:200,top:0,minHeight:'100vh'  ,overflowY:'auto' ,width:'100vw',left:0,backgroundColor:'rgba(0,0,0,0.85)',paddingLeft:'0px' }} onClick={()=>{
            props.setShowMenuManual(false)
             }}>
            <div className={props.showMenu && width <= Theme.main.breakPoint ? classes.drawerActiveDrop : classes.drawerClosedDrop} style={{position:'fixed',display:'flex',flexFlow:'column',overflowX:'hidden',zIndex:10  ,backgroundColor: Theme.main.colorTertiary,boxShadow:'0px 0px 25px rgba(0,0,0,0.5)',border:'solid 0px rgba(255,255,255,0.25)'}} onClick={(e)=>{
            e.stopPropagation();console.log("manmual")
            }}> 
            
             <div style={{display:'flex',flexFlow:"column",alignItems:'center',padding:'0px',justifyContent:'center'}}> 
             <AiFillCloseCircle onClick={()=>{ props.setShowMenuManual(false)}} 
                style={{color:'white',marginTop:'20px' ,fontSize:'35px',alignSelf:'center' }}/> 
            <Header style={{color:'white',display:'flex',marginBottom:'-20px',fontSize:'36px',flexFlow:'row',alignItems:'center',textShadow:'0px 0px 1px black'}}>{props.currentName}</Header> 
            <hr style={{width:'80%',border:'2px solid white',backgroundColor:'white'}}></hr>
            </div> 
            
             
            <div className={props.navigationShow ? classes.hoverShrink :classes.hoverGrow} style={{border:'solid 1px rgba(255,255,255,0.00)',overflow:'hidden',justifyContent:'center',display:'flex',flexFlow:'column',alignSelf:'center', margin:'5px',marginBottom:"15px",width:'80%',padding:'0px',borderRadius:'1px',background:'rgba(255,255,255,0.00)'}}>
            {props.getLinks()}
            </div>  
               
            </div>
        </div>
    )
}
const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(SideDrawer)

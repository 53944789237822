import React ,{useState,useEffect} from 'react'
import Header from '../../Components/Header/Header'
import useTheme from '../../Components/Theme/useTheme'
import useWindowDimensions from '../../Components/useWindowDimensions'
import {GiMeshNetwork} from 'react-icons/gi'
import {BiCameraMovie} from 'react-icons/bi'
import {AiOutlinePhone} from 'react-icons/ai'
import {BsFillPersonFill,BsPhone} from 'react-icons/bs' 
const SignupSelector = (props) => {
    const {height,width} = useWindowDimensions()
    const Theme = useTheme()
    const [icon,setIcon] = useState(null)
    const [name,setName] = useState("")
    const [backgroundColor,setBackgroundColor] = useState('rgba(0,0,0,0.7)')
    useEffect(()=>{
        if(props.selected && width < Theme.main.breakPoint){
            setBackgroundColor("white")
        } else {
            setBackgroundColor("rgba(0,0,0,0.7)")
        }
        switch(props.type){
            case 0:
                setIcon(<GiMeshNetwork style={{fontSize:'100px',margin:'10px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName("Internet")
            break;
            case 1:
                setIcon(<BiCameraMovie style={{fontSize:'100px',margin:'10px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName("TV")
            break;
            case 2:
                setIcon(<AiOutlinePhone style={{fontSize:'100px',margin:'10px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName("Phone")
            break;
            case 5:
                setIcon(<BsPhone style={{fontSize:'100px',margin:'10px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName("Wireless")
            break;
            case 3:
                setIcon(<BsFillPersonFill style={{fontSize:'100px',margin:'10px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName("No Rep.")
            break;
            case 4:
                setIcon(<img src={props.emp.img} style={{width:'55%',maxWidth:"200px",height:"auto",borderRadius:'8px',margin:'5px',transitionDuration:"500ms",color:props.selected?Theme.main.colorTertiary:'black'}}/>)
                setName(props.emp.name)
            break;
        }
    },[width,props.selected])
    const signUpCSS ={minWidth: width < Theme.main.breakPoint +200 ? '45vw': "30vw",maxWidth:width >= Theme.main.breakPoint+200 ? 'auto':'auto',borderRadius:'0px', height:width < Theme.main.breakPoint   ? "250px":'375px',flex:1,margin:width < Theme.main.breakPoint ? '5px' :'auto',marginTop:width < Theme.main.breakPoint ?'10px':'0px',marginBottom:width < Theme.main.breakPoint ?'10px':'0px',display:'flex',flexFlow:"column",backgroundColor:"white",justifyContent:'center',alignItems:'center' , boxShadow:"0px 0px 3px "+backgroundColor,alignSelf:"center"}
   
    return (
        <div onClick={()=>{
            if(!props.selected){
                setBackgroundColor("white")
            } else {
                setBackgroundColor("rgba(0,0,0,0.7)")
            }
            props.setSelected();
        }} style={props.style?{transitionDuration:"500ms",...props.style, ...signUpCSS} :{transitionDuration:"500ms",...signUpCSS} }>
            {icon}
            <Header style={{width:'100%',color:props.selected?Theme.main.colorTertiary:'black',fontSize:props.type === 3 || props.type === 4 ? "18px" :Theme.header.fontSize,transitionDuration:"500ms",alignSelf:"center",textAlign:'center'}}>{name}</Header> 
        </div>
    )
}

export default SignupSelector

import React from 'react'
import useTheme from '../Theme/useTheme'
const Title = (props) => { 
    const Theme = useTheme();
    return ( 
            <div ref={props.refer} onClick={()=>{
                if(props.onClick){
                    props.onClick()
                }
            }} className={props.stylers?props.stylers:''}  style={props.style?{...Theme.title,...props.style} :{...Theme.title}}>
                {props.children} 
            </div>
    )
} 
export default Title

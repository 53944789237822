const Types = {
    setShowMenu:'SET_SHOW_MENU',
    setShowMenuManual:'SET_SHOW_MENU_MANUAL',
    setAuth:'SET_AUTH',
    setCurrentName:'SET_CURRENT_NAME',
    setUser:'SET_USER', 
    setToolbarDisplay:'SET_TOOLBAR_DISPLAY',
    setPreferences:'SET_PREFERENCES',
    setShowModal:'SET_SHOW_MODAL',
    setModalContent:'SET_MODAL_CONTENT',
    setScroll:'SET_SCROLL', 
    setNavigation:'SET_NAVIGATION',
    setScrollTop:'SET_SCROLL_TOP',
    setEmployees:'SET_EMPLOYEES',
    setDeviceType:'SET_DEVICE_TYPE',
    setData:'SET_DATA'
 }
 export default Types;
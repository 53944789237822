import React,{useState,useEffect} from 'react'
import useTheme from '../Theme/useTheme' 
import classes from './CDiv.module.css'
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import useWindowDimensions from '../useWindowDimensions'
import { connect } from 'react-redux';
const CDiv = (props) => {
    const [padBottom,setPadBottom] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const Theme = useTheme(); 
    const handdleScroll = (e) =>{
        let element = e.target  
        if(element.scrollTop > 0   ){  
            if(element.scrollTop > props.scrollTop + 220){ 
                // setScrollTop(element.scrollTop)
                
                props.setScrollTop(element.scrollTop )
            } else if(element.scrollTop < 0){
                // setScrollTop(element.scrollTop)  
                props.setScrollTop(element.scrollTop )
 
            } 
 
                props.setScroll(true)  
        } else {
            props.setScroll(false)
        } 
}
    useEffect(()=>{  
        try{
             
            if(window.navigator.platform === 'iPhone' ){
                setPadBottom(true)
                props.setDeviceType('mobile')
            } 
            else {
                let subText = window.navigator.platform.substring(0,5)  
                if(subText === 'Linux'){ 
                    setPadBottom(true)
                    props.setDeviceType('mobile')
                } else {
                    setPadBottom(false) 
                    props.setDeviceType('desktop')
                }
            }
        } catch (e){
                console.log(JSON.stringify(e))
        } 
    },[])
    return ( 
        <div onScroll={(e)=>{handdleScroll(e)}}  className={(!props.showMenu) ? classes.drawerClosed:classes.drawerActive } style={props.style?{...Theme.stylePageMainDiv,...props.style,backgroundRepeat:'no-repeat' ,transition:'all 750ms ease-in-out',top:props.scroll? '0' : '0vh'   ,width:'100%',backgroundImage:'url( Theme.main.backgroundImage )',backgroundRepeat:'no-repeat',backgroundPosition:'center',height:'100vh'    } :{...Theme.stylePageMainDiv ,backgroundRepeat:'no-repeat',transition:'all 750ms ease-in-out',top:props.scroll? '0' : '10vh',height:'100vh'   }}>
            
            <div    style={{...Theme.basicCenter ,width:'100%',minHeight:'90vh',paddingBottom:padBottom?'13vh':0 }}>  
                {props.children}
            </div>
        </div>
    )
} 
const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(CDiv)

const toolbarStateToProps = (state) =>{  
    return {
    auth:state.toolbar.auth,
    user:state.toolbar.user,
    showMenu:state.toolbar.showMenu,  
    currentName:state.toolbar.currentName, 
    toolbarDisplay:state.toolbar.toolbarDisplay,
    showModal:state.toolbar.showModal,
    modalContent:state.toolbar.modalContent, 
    scroll:state.toolbar.scroll , 
    bills:state.toolbar.bills ,
    work:state.toolbar.work,
    navigationShow:state.toolbar.navigationShow,
    scrollTop:state.toolbar.scrollTop,
    employees:state.toolbar.employees,
    deviceType:state.toolbar.deviceType,
    data:state.toolbar.data
    }
}
 export default toolbarStateToProps;
import React from 'react'
import useTheme from '../Theme/useTheme'
const Text = (props) => { 
    const Theme = useTheme();
    return ( 
        <div style={props.style?{...Theme.text,...props.style} :{...Theme.text}}>
                {props.children} 
            </div>
    )
} 
export default Text

import React,{useState,useEffect,useRef} from 'react' 
import CDiv from '../../Components/CDiv/CDiv' 
import useTheme from '../../Components/Theme/useTheme' 
import './styles.module.css' 
import useWindowDimensions from '../../Components/useWindowDimensions' 
import firebase from 'firebase';
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux'; 
import { useHistory } from "react-router-dom";   
import Carousel from '../../Components/Carousel/Carousel'
import {BiMessageDots} from 'react-icons/bi'
import {TextField} from '@material-ui/core'
import Button from '../../Components/Button/Button'
import Header from '../../Components/Header/Header'
import Title from '../../Components/Title/Title' 
import useMeasure from 'react-use-measure'
import { useTrail, animated } from '@react-spring/web' 
import Loader from 'react-loader'
import styles from './styles.module.css'  
import PromoView from '../../Components/PromoView/PromoView'
import Text from '../../Components/Text/Text'
import smoothscroll from 'smoothscroll-polyfill'; 

import { makeStyles } from '@material-ui/core/styles';
window.__forceSmoothScrollPolyfill__ = true;
// kick off the polyfill!
smoothscroll.polyfill();
const fast = { tension: 1200, friction: 40 }
const slow = { mass: 10, tension: 200, friction: 50 }
const trans = (x, y) =>
  `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`

const queryString = require('query-string');
const Home = (props) => {
    let observer = useRef(null)
    let observer1 = useRef(null)
    const Theme = useTheme()
    const [firstLoad,setFirstLoad] = useState(false) 
    const [animate,setAnimate] = useState(false)
    const [scrolling,setScrolling] = useState(false)
    const [scrolling1,setScrolling1] = useState(false)
    const [data,setData]= useState({email:'',title:'',message:''})
    const [animate1,setAnimate1] = useState(false)
    const scroller = useRef()
    const history = useHistory()  
    const useStyles = makeStyles((theme) => ({
         root: {
             background: 'rgba(0, 0, 0,0)',
             border:'1px solid white',
             color:"white",
             padding:'3px',
             input:{
               color:"white"
             }
          },
          input:{ 
            minHeight:"50px",
            color:'white' ,
            fontSize:"20px"
          }
    }));
    const classes = useStyles();
    const [message, setMessage] = useState({name:'',email:'',phone:'',message:'',confirmed:false}) 
    const {height,width} = useWindowDimensions()   
      
    const [trail, api] = useTrail(2, i => ({
      xy: [75, 175],
      config: i === 0 ? fast : slow,
    }))
    const [ref, { left, top }] = useMeasure() 
    const handleMouseMove = e => {
      api.start({ xy: [e.clientX - left, e.clientY - top] })
    } 
    const ParallaxText = () => (  
        <div style={{display:'flex' ,flexFlow:width<Theme.main.breakPoint ? 'column' : 'row',justifyContent:'center',alignItems:'center',minHeight:'100vh',width:'100vw',backgroundColor:'rgba(255,255,255,0.85)'}}  >
              <Header  style={{zIndex:'50',padding:width < Theme.main.breakPoint ? '15px' : '35px',marginBottom:width<Theme.main.breakPoint ? '30px':"10px",margin:'10px' ,color:Theme.main.colorTertiary,textShadow:'0px 0px 2px rgba(0,0,0,0.95)' ,borderTopRightRadius:'10px',borderTopLeftRadius:'10px',width:"80%",marginBottom:'0px',}}  >{props.data && props.data.home && props.data.home.section1 ? props.data.home.section1.header : null }</Header> 
              <Text style={{textAlign:'start',color:'black',textIndent:'25px',boxShadow:Theme.main.boxShadow,border:'1px solid '+ Theme.main.colorTertiary,backgroundColor:'rgba(255,255,255,0.85)',zIndex:'50',padding:width < Theme.main.breakPoint ? '15px' : '35px',borderRadius:'10px',borderBottomLeftRadius:'10px' ,textShadow:'0px 0px 0.5px white',margin:'10px',marginTop:'0px', width:'80%' }}  >{props.data && props.data.home && props.data.home.section1 ? props.data.home.section1.title : null }</Text>
                
        </div>
 
  ); 
  const getObserver = (observer,observer1) =>{
    if(observer && observer1){
      let observe = new IntersectionObserver((entries)=>{
              console.log([entries[0]]  );
              console.log('observed ' + entries[0].isIntersecting+ " "+ entries[0].intersectionRatio)
              if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.595){
                
                setAnimate(true)
              }
            })
        let observe1 = new IntersectionObserver((entries)=>{ 
          if(entries[0].isIntersecting && entries[0].intersectionRatio > 0.3){
             
            setAnimate1(true)
            
            
              } 
        })
      observe.observe(observer.current) 
      observe1.observe(observer1.current)
      return [observe,observe1]
    }
    return null
  }
    useEffect(() => { 
        if(!firstLoad){
            setFirstLoad(true)
            props.setCurrentName("Home") 
            let params = queryString.parse(history.location.search) 
            const id =  params.confirmId
           
        } 
        let observe = null
        if( props.data){
         observe =  getObserver(observer, observer1)
        } 
        return()=>{ 
          if(observe && observe.length > 0){
             observe.map((item,index)=>{
              item.disconnect()
             }) 
          } 
        }
    }, [firstLoad,width,message,props.scroll,animate,animate1,props.data ])
     const checkSend = ()=>{
        if(data.title === '' || data.message === '' || data.email === ''){
          return false
        }
        // if(!email.includes("@") || !email.includes(".")){
        //   return false
        // }
        return true
     }
    return (
      
      props.data ? <CDiv>  
      {/* first section */}
      <div className={styles.container} style={{ }}>
              <svg src='' style={{ position: 'absolute', width: 0, height: 0 }}>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7"
                  />
                </filter>
              </svg>
              <div ref={ref} className={styles.hooksMain} onMouseMove={handleMouseMove}>
                {trail.map((props, index) => (
                  <animated.div key={index} style={{ transform: props.xy.to(trans) }} />
                ))} 
              </div>
              {ParallaxText()}
             
      </div>
      <div ref={scroller} style={{height:'85vh',justifyContent:"center",alignItems:"center",flexFlow:"column",display:'flex',width:'100vw',backgroundColor:Theme.main.colorTertiary}}>
        <Header refer={observer1}   stylers={animate1?  styles.animateslide :styles.nonanimateslide} style={{color:'white' }} >{props.data && props.data.home && props.data.home.section2 ? props.data.home.section2.header : null }</Header>
        <Title refer={observer1}   stylers={animate1?  styles.animateslide :styles.nonanimateslide} style={{color:'white',textAlign:'center' }} >{props.data && props.data.home && props.data.home.section2 ? props.data.home.section2.title : null }</Title>
      </div>
      <div ref={observer} className={animate ? styles.animate :styles.nonanimate}  > 
       <Carousel hideButtons={true} classNames={animate ? styles.animate :styles.nonanimate}  buttonText="Signup" buttonColor={Theme.main.colorTertiary}    width={'100vw'}  height={props.deviceType === "mobile" ? '80vh' :'80vh'} items={[{name:'At&t Fiber',
          button:'Fiber',
                                                            img:'https://firebasestorage.googleapis.com/v0/b/sterlinggroupsite.appspot.com/o/pexels-guillaume-meurice-3363556.jpg?alt=media&token=7d64d1ad-8b09-4374-a913-d8db412b4503',
                                                            intro:'How fast is fast enough?',
                                                            rest:'Speeds up to 1000 Mbps and beyond. ',
                                                            ref:''}, 
                                                            {name:'At&t Cable',
                                                            button:'Cable',
                                                            img:'https://images.pexels.com/photos/1040158/pexels-photo-1040158.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
                                                            intro:'Never miss the game with At&t premier packages.',
                                                            rest:'Lorem Ispsom text here Lorem Ispsom text here Lorem Ispsom text here Lorem Ispsom text here'},
                                                            {name:'At&t Phone',
                                                            button:'Phone',
                                                            img:'https://images.pexels.com/photos/7113753/pexels-photo-7113753.jpeg?cs=srgb&dl=pexels-cottonbro-7113753.jpg&fm=jpg',
                                                            intro:'Lorem Ispsom ',
                                                            rest:'Lorem Ispsom text here Lorem Ispsom text here Lorem Ispsom text here Lorem Ispsom '},
                                                             
                                                            {name:'Solar',
                                                            button:'Solar',
                                                            img:'https://images.pexels.com/photos/371917/pexels-photo-371917.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                                                            intro:'Are you Missing Out on Solar Power?',
                                                            rest:'Save money, go green. Everyone wins.'},
                                                            {name:'Security',

                                                            button:'Security',
                                                            img:'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                                                            intro:'Our Security Services',
                                                            rest:'People protect their homes at two different times in their life 1. Within 30 days of moving into a new home and 2. After something happens. Our job is to keep you out of that second category. Protect your family today'},
                                                            {name:'Wellness',
                                                            button:'Wellness',
                                                            img:'https://images.pexels.com/photos/37351/silhouette-aerialist-female-woman.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                                                            intro:'Feel Better Live Better, Melaleuca',
                                                            rest:'HEALTHY ENVIROMENT plant based products for a healthy home and safe for the environment. POWERFUL these products are clinically tested to provide super cleaning products, that is affordable for everyone. Join in the Wellness for a safe clean home'}]}/>
    
       
       </div> 
      <div style={{minHeight:'90vh',width:'100vw',justifyContent:'center',display:"flex",flexFlow:'column',alignItems:'center',backgroundColor:Theme.main.colorTertiary}}>
        
         
        <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"5vh",justifyContent:'center',alignItems:"center"}}>
            <div style={{display:'flex',width:width<Theme.main.breakPoint + 200 ? '95%':'45%',height:'auto',flexFlow:"column" ,justifyContent:'center',alignItems:"center"}}>
            <BiMessageDots style={{fontSize:'300px' ,padding:'30px',marginTop:"12vh",marginBottom:"-6vh"  , transitionDuration:"500ms",color:'white'}}/> </div>  
            
        </div> 
        <div style={{display:'flex',marginBottom:"15vh",width:'100%',flexFlow:"column",justifyContent:'center',alignItems:'center'}}>
        <form  noValidate autoComplete="on">
        <Title style={{textAlign:'end',color:"white",width:width < Theme.main.breakPoint ?'90vw' :'50vw'}} >Email</Title>
        <TextField
          onChange={e=>{setData({...data,email:e.target.value})}}
         InputProps={{
          className: classes.input
        }}  className={classes.root} style={{width:width < Theme.main.breakPoint ?'90vw' :'50vw',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""  />
        <Title style={{textAlign:'end',color:'white',width:width < Theme.main.breakPoint ?'90vw' :'50vw'}} >Title</Title>
        <TextField  
         onChange={e=>{setData({...data,title:e.target.value})}}
        InputProps={{
          className: classes.input
        }}  className={classes.root} style={{width:width < Theme.main.breakPoint ?'90vw' :'50vw',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />
        
        <Title style={{textAlign:'end',color:'white',width:width < Theme.main.breakPoint ?'90vw' :'50vw'}} >Message</Title>
        <TextField
         onChange={e=>{setData({...data,message:e.target.value})}}
        style={{width:width < Theme.main.breakPoint ?'90vw' :'50vw',color:'white',marginBottom:"2vh"}}
         className={classes.root} 
         InputProps={{
          className: classes.input
        }}
          id="outlined-multiline-basic" 
          label=""
          multiline
          rows={8} 
        /></form>
          <Button click={  ()=>{checkSend()? firebase.firestore().collection('messages').doc().set({...data}).then(doc=>{alert("Sent!.")}).catch(e=>{alert(e.message)}) : alert("Please fill out all fields.")}} styler={{opacity:1,backgroundColor:"white",width:"250px",fontWeight:'900',border:'none',minHeight:'auto',height:"auto",minWidth:"250px",margin:'auto',marginTop:"25px",boxShadow:'0px 0px 20px rgba(0,0,0,0.35)',borderRadius:'0px',padding:'20px',transitionDuration:"1s"}}>Contact Us</Button>  
       
        </div>
        
        </div> 
      </CDiv> : <Loader/>
      
    
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state) }
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch) }
}
export default connect(mapStateTopProps,mapDispatchToProps)(Home) 

import React ,{useRef} from 'react'
import useTheme from '../Theme/useTheme'
const Header = (props) => { 
    const Theme = useTheme(); 
    return ( 
            <div ref={props.refer} onClick={()=>{
                if(props.onClick){
                    props.onClick()
                }
            }} className={props.stylers?props.stylers:''} style={props.style ? {...Theme.header,...props.style} : {...Theme.header}}>
                {props.children} 
            </div>
    )
} 
export default Header

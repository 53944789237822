import React from 'react'
import useWindowDimensions from '../useWindowDimensions'
import useTheme  from '../Theme/useTheme'
import Title from '../Title/Title'
import Header from '../Header/Header'
import Text from '../Text/Text'
import classes from './PromoView.module.css'
const PromoView = (props) => {
    const {height,width} = useWindowDimensions()
    const Theme = useTheme(width)
    return (
        <div onClick={props.click?()=>{props.click()}:()=>{console.log("no click")}}  className={classes.promo} id="body" style={{minWidth:width<Theme.main.breakPoint?'47vw':'300px',maxWidth:width<Theme.main.breakPoint?'47vw':'300px',boxShadow:props.invert?'none':'0px 0px 5px rgba(0,0,0,0.5)',border:props.invert?'1px solid white':'none',margin:'auto',marginBottom:'3vh',borderRadius:'2px',padding:"2px",backgroundColor:Theme.main.colorTertiary,minHeight:width<Theme.main.breakPoint?'300px':'425px',zIndex:'5',display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}} >
            <div style={{width:'100%',height:'auto',display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}} id="topText">
                <Header style={{fontSize:'16px',color:'white',padding:'0px',margin:'7px'}}>{props.item? props.item.top:''}</Header>
            </div>
            <div style={{width:'100%',height:'auto',display:'flex',flexFlow:'row',justifyContent:'center',alignItems:'center'}} id="priceMainRow"> 
            <div style={{width:'auto',height:'70%',display:'flex',top:0,flexFlow:'column',justifyContent:'flex-start',alignItems:'flex-start'}} id="centsOverMonth">
                <Header style={{fontSize:'28px',color:'white',padding:'0px',margin:'0px'}}>$</Header> 
                </div>
            <Header style={{fontSize:'70px',marginTop:"15px",marginBottom:'15px',color:'white',padding:'0px',marginRight:'2px'}}>{props.item? props.item.dollars:''}</Header>

                <div style={{width:'auto',height:'auto',display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}} id="centsOverMonth">
                <Header style={{fontSize:'20px',color:'white',padding:'0px',margin:'0px'}}>{props.item? props.item.cents:''}</Header>
                <hr  style={{width:'30px',border:'2px solid white',backgroundColor:'white'}}></hr>
                <Header style={{fontSize:'20px',color:'white',padding:'0px',margin:'0px'}}>mo</Header>
                </div>
                
            </div>
            <div style={{width:'auto',height:'auto',display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}} id="underPriceText">
            <Text style={{fontSize:'14px',color:'white',fontWeight:'900',padding:'0px',maxWidth:width<Theme.main.breakPoint?'48vw':'300px',textAlign:'center',marginTop:'0px'}}>{props.item? props.item.middle:''}</Text>
            <Title style={{fontSize:'14px',color:'white',textAlign:'center',maxWidth:width<Theme.main.breakPoint?'48vw':'300px',padding:'0px',marginBottom:'7px'}}>{props.item? props.item.bottom:''}</Title> 
            </div>
            {/* <div style={{width:'100%',flex:1,backgroundColor:'whitesmoke',height:'auto',display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}} id='image div'>
                image
            </div> */}
            
        </div>
    )
}

export default PromoView

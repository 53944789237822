import React , {useState,useEffect,useRef} from 'react'
import Header from '../../Components/Header/Header'
import TextField from '@material-ui/core/TextField';
import Button from '../../Components/Button/Button'; 
import firebase from 'firebase'
import useTheme from '../../Components/Theme/useTheme';
import Loader from 'react-loader'  
import Resizer from 'react-image-file-resizer';
import ImageCropper from '../../Components/ImageCropper/ImageCropper'
import Text from '../../Components/Text/Text'
import { ThemeProvider } from '@material-ui/core';
import BizCard from './BizCard'
import ModalDivWrapper from '../../Components/ModalDivWrapper/ModalDivWrapper';
var sizeof = require('object-sizeof');
var QRCode = require('qrcode.react');
const EditUser = (props) => {
    const [newData, setNewData] =useState(null)
    const [showSave,setShowSave] = useState(false)
    const Theme = useTheme()
    const [showCropper,setShowCropper] = useState(false)
    const[resizing, setResizing] = useState(false)
    const [inputImg, setInputImg] = useState('')
    const imgs = useRef('getFile') 
    useEffect(() => {
        if(newData === null){
            setNewData({...props.user})
        }else{
            if(newData.name !== props.user.name || newData.bio !== props.user.bio || newData.img !== props.user.img || newData.email !== props.user.email || newData.phone !== props.user.phone){
                setShowSave(true)
            } else {
                setShowSave(false)
            }
        }
        
    }, [newData])
    
    const addTopArray = (stringImage)=>{  
        setNewData({...newData,img:stringImage})   
}
const saveDataImageArray = (stringImage)=>{ 
    setInputImg(stringImage)
    
} 
  const resizeImage = (address, amount,force) => {
     
      Resizer.imageFileResizer(
          address,
          750,
          750,
          'JPEG',
          amount,
          0,
          uri => {
              const anotherAddress = encodeURI(uri) 
              
              if(sizeof(anotherAddress) < 99000 || force){
              saveDataImageArray(anotherAddress)
              setResizing(false)
              }
              else if((amount < 0)) {
                resizeImage(address,  1,true) 
                     
              } else  {
                  console.log("Resizingnow")
                  if(amount - 1 <= 0){
                    saveDataImageArray(anotherAddress)
                    setResizing(false)
                  } else {
                    resizeImage(address,  amount - 1)
                  }
                  
              }   
          },
          'base64'
      );
  }
  const handleImageSelected = (event) =>{
      console.log("imageSelected")
      var fileInput = false
      const newAddress = event.target.files[0] 
     try{
        if(newAddress) {
            fileInput = true
        }
        if(fileInput) {
            resizeImage(newAddress, 95)
            setResizing(true)
        }
     } catch(e){
         console.log("err0r: " + e.message)
     }
     
  }
    return (
       
        <ModalDivWrapper>
             <Header style={{fontSize:'34px'}}>Edit User #{props.user.id}</Header> 
             <div style={{width:"100%",display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>

             
             <div style={{border:'solid black 1px',margin:'15px' ,width:"332px", display:"flex",flexFlow:'column',justifyContent:'center',alignItems:"center"}}> 
                     <img onClick={()=>{imgs.current.click()}} style={{width:'40%',height:'auto',margin:'5px'}} src={newData? newData.img : props.user.img} />
                     <input type='file' ref={imgs}  style={{display:'none'}}  onChange={e => {handleImageSelected(e)}}></input>
                     {resizing ? <div><h5>Adding Image...</h5><Loader loaded={!resizing}  /></div> : null} 
                { inputImg === '' ? null :  <ImageCropper
                                getBlob={addTopArray}
                                inputImg={inputImg}
                                setInputImg={setInputImg}
                            />}  
           
                    <TextField onFocus={(e)=>{
                           window.scrollTo(0, 0);
                           document.body.scrollTop = 0;
                    }} onChange={(e)=>{ e.preventDefault();setNewData({...newData,name:e.target.value})}} value={newData ? newData.name : null} style={{margin:'10px'}} id="outlined-basic" label="Name" variant="outlined" />
                     <TextField onFocus={(e)=>{
                           window.scrollTo(0, 0);
                           document.body.scrollTop = 0;
                    }} onChange={(e)=>{ e.preventDefault();setNewData({...newData,email:e.target.value})}} value={newData ? newData.email : null} style={{margin:'10px'}} id="outlined-basic" label="Email" variant="outlined" />
                   <TextField onFocus={(e)=>{
                           window.scrollTo(0, 0);
                           document.body.scrollTop = 0;
                    }} onChange={(e)=>{ e.preventDefault();setNewData({...newData,phone:e.target.value})}} value={newData ? newData.phone : null} style={{margin:'10px'}} id="outlined-basic" label="Phone" variant="outlined" />
                   
                    <TextField onFocus={(e)=>{
                           window.scrollTo(0, 0);
                           document.body.scrollTop = 0;
                    }}  onChange={(e)=>{ e.preventDefault();setNewData({...newData,bio:e.target.value})}} value={newData ? newData.bio : null} style={{margin:'10px'}} id="outlined-basic" label="Bio"  multiline rows={4} variant="outlined" /> 
              
            </div>
            <BizCard userIn={{...newData,id:props.user.id}}/>
             </div>
            <div style={{display:"flex",width:"100%",marginTop:"5px",justifyContent:"center",alignItems:'center'}}>
            <Button styler={{opacity:showSave? 1 : 0,transition:'all 500ms ease-in-out',margin:"auto"}} click={(e)=>{
                firebase.firestore().collection("employees").doc(props.user.id+"").set({...newData}).then(doc=>{
                    alert("Saved!")
                    props.closeModal()
                })
            }}>Save</Button>
            </div>
    </ModalDivWrapper>
    )
}

export default EditUser

import React,{useState, useEffect} from 'react' 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux'; 
import firebase from 'firebase/app'
import useTheme from '../../Components/Theme/useTheme'
import CDiv from '../../Components/CDiv/CDiv'
import Employee from '../UserHome/Employee'
import Title from '../../Components/Title/Title';
import Header from '../../Components/Header/Header';

var Loader = require('react-loader');
const Who = (props) => {
    const Theme = useTheme();  
    const [firstLoad, setFirstLoad] = useState(false)
    const [items,setItems] = useState()   
    const array10 = new Array(25).fill(' ')
    useEffect(()=>{
        if(!firstLoad){
            setFirstLoad(true)
            props.setCurrentName("Our Employees")
        }
    },[])
    useEffect(()=>{

        let listener = null; 
            try{
                listener = firebase.firestore().collection("employees")
             .onSnapshot((querySnapshot) => {
             var setter = [];
             querySnapshot.forEach((doc) => { 
                 setter.push({...doc.data(),id:doc.id});
             });
             let sorted = setter.slice().sort((a, b) =>a.id - b.id);
             props.setEmployees(sorted) 
             if(sorted.length > 0){
                setItems(sorted.map((item,index)=>{ 
                        if(item.name === ''){
                            return 
                        }
                    return  <Employee key={item.id} public={true} index={item.id} employee={{...item}} />

                })  )
               
             } 
            //  else {
            //     setItems(array10.map((item,index)=>{ 
            //         return  <Employee public={true}  index={index+1} employee={{name:'Name Here ',img:'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',bio:'Bio Here'}} />
            //     })  )
            //  }

         });
             } catch(e){
                 console.log(e.message)
             } 
             return()=>{
                 return listener ? listener() : null
             }
      
    },[props.user])
    return (
        <CDiv style={{backgroundImage:Theme.main.backgroundImage,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundColor:'rgba(0,0,0,0.05)'}}> 
           <Header style={{marginTop:'10vh',color:"white"}}>Our Employees</Header>
               <div style={{display:"flex",backgroundColor:'white',borderTopLeftRadius:'900%', minHeight:'80vh',marginTop:'3vh',flexWrap:'wrap',justifyContent:'center',alignItems:'center',width:'100%'}}>
                {items ? items:<Loader loaded={items}/>} 
               </div>
          
       </CDiv> 
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state) }
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch) }
}
export default connect(mapStateTopProps,mapDispatchToProps)(Who) 

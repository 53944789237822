import React,{useState,useEffect} from 'react'
import Header from '../Header/Header'
import Title from '../Title/Title'
import Button from '../Button/Button'
import ModalDivWrapper from '../ModalDivWrapper/ModalDivWrapper'
import { ThemeConsumer } from 'styled-components'
import useTheme from '../Theme/useTheme'
import useWindowDimensions from '../useWindowDimensions'
import { makeStyles } from '@material-ui/core/styles';
import {TextField} from '@material-ui/core'
import firebase from 'firebase'
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select'; 
import MenuItem from '@material-ui/core/MenuItem';
const Signup = (props) => {
    const [ui,setUi] = useState(null)
    const Theme = useTheme()
    const {height, width} = useWindowDimensions()
    const [show,setShow] = useState(false) 
    const [show2,setShow2] = useState(false)
    const [firstLoad,setFirstLoad] = useState(true)
    const [data,setData] = useState({firstname:'',lastname:'',phonenumb:'',email:'',address:'',city:'',state:'',zip:'',tv:'',lineCount:'',wirelessCount:'',internet:'',solar:false,security:false,wellness:false})
     
    useEffect(()=>{  
        if(firstLoad){
            setFirstLoad(false)
            if(props.type==="OTHER"){
                setData({...data,solar:props.itemsother[0],security:props.itemsother[1],wellness:props.itemsother[2]})
            }
        }
        
            checkFirst() 
    },[ width,show2,data,show,props.user])

    const useStyles = makeStyles((theme) => ({
        root: {
            background: 'rgba(0, 0, 0,0)',
            border:'1px solid white',
            color:"white",
            padding:'3px',
            input:{
              color:"white"
            }
         },
         input:{ 
           minHeight:"50px",
           color:'white' ,
           fontSize:"20px"
         }
   }));
   const classes = useStyles();
   const onEmailChange=(email)=>{ 
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()); 
} 
   const checkFirst=()=>{
       //need phonenumber and email and zipcode validation, for now this will work
        if(data.firstname.length === 0 || data.lastname.length === 0 || data.email.length === 0 ||    data.address.length === 0 || data.city.length === 0 || data.state.length === 0 || data.zip.length === 0){
            setShow(false)
        } else {
            setShow(true)
        }
   }
    return (
        < ModalDivWrapper style={{backgroundColor:Theme.main.colorTertiary ,overflow:'hidden',justifyContent:"center",minWidth:"100%",maxWidth:"100%",width:"100%",height:'95%',overflowX:"hidden",overflowY:"auto"}}>
             
             <div style={{display:'flex',flexFlow:'column',width:'100%',height:"auto",paddingBottom:'2vh',display:'absolute'}}>
            <div style={{display:"flex",width:"100%",height:"auto",height:"70vh",flexFlow:'row',justifyContent:'center',overflowX:"hidden",overflowY:"auto",alignItems:'center',flexWrap:"wrap",position:'relative' ,left:show2?'-200vw':'0vw',transition:"all 750ms ease-in-out" }}>
                <div style={{display:"flex",width:width<Theme.main.breakPoint ? '85%' : "40%",margin:'auto',eight:"auto",flexFlow:'column'}}>
                    <Title style={{textAlign:'start',color:'white',width:'100%'}} >First Name</Title>
                    <TextField onChange={(e)=>{setData({...data,firstname:e.target.value}) }} value={data.firstname}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                    <Title  style={{textAlign:'start',color:'white',width:'100%'}} >Last Name</Title>
                    <TextField onChange={(e)=>{setData({...data,lastname:e.target.value}) }}  value={data.lastname} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                    <Title style={{textAlign:'start',color:'white',width:'100%'}} >Email</Title>
                    <TextField onChange={(e)=>{setData({...data,email:e.target.value}) }}  value={data.email} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                    <Title style={{textAlign:'start',color:'white',width:'100%'}} >Phone Number</Title>
                    <TextField onChange={(e)=>{setData({...data,phonenumb:e.target.value})}} value={data.phonenumb} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
               
                </div>
                <div style={{display:"flex",width:width<Theme.main.breakPoint ? '85%' : "40%",margin:'auto',height:"auto",flexFlow:'column'}}>
                    <Title style={{textAlign:'start',color:'white',width:'100%'}} >Address</Title>
                    <TextField onChange={(e)=>{setData({...data,address:e.target.value}) }} value={data.address}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                    <Title style={{textAlign:'start',color:'white',width:'100%'}} >City</Title>
                    <TextField onChange={(e)=>{setData({...data,city:e.target.value}) }} value={data.city}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />  
                    <div style={{display:"flex",width:"100%",height:"auto",flexFlow:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{display:"flex",width:"auto",margin:'auto',marginRight:'8px',flex:1,height:"auto",flexFlow:'column'}}>
                            <Title style={{textAlign:'start',color:'white' }} >State</Title>
                            <TextField onChange={(e)=>{setData({...data,state:e.target.value}) }} value={data.state}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                        </div>  
                        <div style={{display:"flex",width:"auto",margin:'auto',marginLeft:'8px',flex:1,height:"auto",flexFlow:'column'}}>
                            <Title style={{textAlign:'start',color:'white' }} >Zip</Title>
                            <TextField onChange={(e)=>{setData({...data,zip:e.target.value}) }}  value={data.zip} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label=""   />    
                        </div>  
                    </div>  
                </div>
            </div>
            <div>
            <div style={{display:"flex",width:"100%",height:'70vh',overflowX:"hidden",overflowY:"auto" ,flexFlow:'row',justifyContent:'center',alignItems:'center',flexWrap:"wrap",position:'absolute',top:'5vh',left:!show2?'-200vw':'0vw',transition:"all 750ms ease-in-out" }}>
                     {ui} 
                     {props.type==="OTHER" ? <Title style={{color:'white',textAlign:'center'}}>All set, Click Submit to send.</Title> : null }
                     {props.items[0] && props.type==="ATT" ? <div style={{display:"flex",margin:'5px',flexFlow:"column",flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}><Title style={{color:'white'}}><b>Please Select the Internet Service You Want</b></Title>
                     <Select
                         InputProps={{ className: classes.input }}  className={classes.root} style={{width:'80%',color:'white',margin:"2vh"}}
                        value={data.internet}
                        onChange={(e)=>{setData({...data,internet:e.target.value})}}
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={props.data && props.data.att.section1 && props.data.att.section1.product0 ? props.data.att.section1.product0.top : ''}>{props.data && props.data.att.section1 && props.data.att.section1.product0 ? props.data.att.section1.product0.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section1 && props.data.att.section1.product1 ? props.data.att.section1.product1.top : ''}>{props.data && props.data.att.section1 && props.data.att.section1.product1 ? props.data.att.section1.product1.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section1 && props.data.att.section1.product2 ? props.data.att.section1.product2.top : ''}>{props.data && props.data.att.section1 && props.data.att.section1.product2 ? props.data.att.section1.product2.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section1 && props.data.att.section1.product3 ? props.data.att.section1.product3.top : ''}>{props.data && props.data.att.section1 && props.data.att.section1.product0 ? props.data.att.section1.product3.top : ''}</MenuItem>
                      </Select></div>:null}
                     {props.items[1]? <div style={{display:"flex",margin:'5px',flexFlow:"column",flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}><Title style={{color:'white'}}><b>Please Select the TV Service You Want</b></Title>
                     <Select
                         InputProps={{ className: classes.input }}  className={classes.root} style={{width:'80%',color:'white',margin:"2vh"}} 
                        value={data.tv}
                        onChange={(e)=>{setData({...data,tv:e.target.value})}}
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={props.data && props.data.att.section2 && props.data.att.section2.product1 ? props.data.att.section2.product1.top : ''} >{props.data && props.data.att.section2 && props.data.att.section2.product1 ? props.data.att.section2.product1.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section2 && props.data.att.section2.product2 ? props.data.att.section2.product2.top : ''}>{props.data && props.data.att.section2 && props.data.att.section2.product2 ? props.data.att.section2.product2.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section2 && props.data.att.section2.product3 ? props.data.att.section2.product3.top : ''}>{props.data && props.data.att.section2 && props.data.att.section2.product3 ? props.data.att.section2.product3.top : ''}</MenuItem>
                        <MenuItem value={props.data && props.data.att.section2 && props.data.att.section2.product4 ? props.data.att.section2.product4.top : ''}>{props.data && props.data.att.section2 && props.data.att.section2.product4 ? props.data.att.section2.product4.top : ''}</MenuItem>
                      </Select></div>:null}
                     {props.items[2]? <div style={{display:"flex",margin:'5px',flexFlow:"row",flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}><Title style={{color:'white'}}><b>How Many Phone Lines Do you Need?</b></Title><TextField onChange={(e)=>{setData({...data,lineCount:e.target.value})}} value={data.lineCount}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'80px',color:'white',margin:"2vh"}} id="outlined-basic" label=""  /></div>:null}
                     {props.items[3]? <div style={{display:"flex",margin:'5px',flexFlow:"row",flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}><Title style={{color:'white'}}><b>How Many Wireless Lines Do you Need?</b></Title><TextField onChange={(e)=>{setData({...data,wirelessCount:e.target.value})}} value={data.wirelessCount}  InputProps={{ className: classes.input }}  className={classes.root} style={{width:'80px',color:'white',margin:"2vh"}} id="outlined-basic" label=""  /></div>:null}
            </div>
            </div>
               
            </div> 
            <Button click={show?(e)=>{setShow2(true)}:()=>{}} styler={{margin:'auto',height:"8vh",padding:"20px",width:"100%",position:'absolute',bottom:props.deviceType === 'mobile' ? '0vh' : '0vh',left:show2?'-200vw':'0vw' , opacity:show?1:0.5}}>Next</Button>
            <Button click={show2?(e)=>{ 
                setShow2(false);setShow(true); 
                firebase.firestore().collection("leads").doc().set({...data,repemail:props.userlocal && props.userlocal.email ? props.userlocal.email : Theme.main.siteEmail})
                .then(next=>{ props.setShowModal(false);alert("submited")})
                .catch(e=>{alert(e.message)})}:()=>{}} styler={{margin:'auto',height:"8vh",padding:"20px",width:"100%",position:'absolute',bottom:props.deviceType === 'mobile' ? '0vh' : '0vh',left:!show2?'-200vw':'0vw' , opacity:show2?1:0}}>Submit</Button>
            </ModalDivWrapper>
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(Signup)  

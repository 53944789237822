import React,{useState,useEffect} from 'react'
import Title from '../../Components/Title/Title'
import Header from '../Header/Header'
import Text from '../Text/Text'
import useWindowDimensions from '../useWindowDimensions'
import useTheme from '../Theme/useTheme'
import {Button} from '@material-ui/core' 
import {AiOutlineDown} from 'react-icons/ai'
import classes from  './Carousel.module.css'
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux';
var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;
const Carousel = (props) => {
    const [current,setCurrent] = useState(0)
    const {height,width} = useWindowDimensions()
    const [firstLoad,setFirstLoad] = useState(true)
    const Theme = useTheme() 
    //onClick={()=>{current < props.items.length-1 ? setCurrent(current+1):setCurrent(0)}} r
    let timer = setTimeout( ()=>{ 
        if(current < props.items.length-1 ){
             setCurrent(current+1)
            }
             else {
            setCurrent(0);
        }  
    }, 5000);
    useEffect(()=>{
      if(firstLoad){
          setFirstLoad(false)
      }
    },[current,props.classNames])
     const styler = (index) => { return {alignSelf:'flex-end',position:'relative',left:current === index ? '0vw':'-250vw',transitionDelay:'500ms',transition:'all 750ms ease-in-out'}}
    return (
        <div  style={{position:'relative',width:props.width,height:props.height,backgroundColor:'rgba(0,0,0,0.25)',overflow:"hidden"  }}>
            {props.items.map((item,index)=>{
                return       <div key={item.name}   style={{position:'absolute' ,display:'block',backgroundImage:'url('+item.img+')',backgroundRepeat:'no-repeat',backgroundSize:'cover',objectFit:'cover',overflow:"hidden" ,backgroundPositionX:width < Theme.main.breakPoint ? '-20vw':'0vw' , left:current === index ? "0vw": current > index ? '100vw' : '-100vw' , width:props.width,height:props.height ,transition:"all 750ms ease-in-out"}} >
                               <div   style={{width:props.width,height:"100%",display:'flex',backgroundColor:'rgba(0,0,0,0.65)'}}>
                              
                                   
                                    <div style={{...styler(index),width:"100%",marginBottom:'2vh',display:"flex",flexFlow:"column" }}>
                                    <Header style={{ position:'relative',color:'whitesmoke',bottom:current === index ? props.hideButtons ? '-15vh' : '0vw':'50vh',left:current === index ? props.hideButtons ? '2vh' : '1vw':'250vw' ,opacity:current === index ?props.hideButtons ? '0.5': '1':'0',alignSelf:'flex-start',margin:'',padding:'',transitionDelay:'500ms',transition:'all 750ms ease-in-out'   }}  >{item.name}</Header> 
                                    <Title  style={{...styler(index),color:'whitesmoke',alignSelf:'flex-start',opacity: props.hideButtons ? 0:1,left:'2vw' ,marginRight:'2vw'   }}>{item.intro} </Title>
                                    <Text style={{...styler(index),color:'whitesmoke',alignSelf:'flex-start' ,opacity: props.hideButtons ? 0:1 ,left:'2vw',marginRight:'2vw'  }}>{item.rest}</Text>
                                        <div style={{width:"100%",transition:'all 750ms ease-in-out',top:props.hideButtons ? '-500vh' : '0',display:"flex",left:'-0vw',position:'relative',justifyContent:"space-evenly",alignItems:'center'}}> 
                                            <Button onClick={()=>{
                                                props.scroller(0)
                                            //    props.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                                            }}  className={classes.buttonHover}    style={{ margin:"10px",border:' 1px solid whitesmoke',marginTop:'15px',height:"45px",borderRadius:'2px',backgroundColor:props.buttonColor? props.buttonColor :"rgba(255,255,255,1.0)",fontWeight:'900',minWidth:'15vw',maxWidth:'120px',position:'relative',flex:1,bottom:current === index ? '-1vh':'-50vh', transitionDelay:'500ms',transition:'all 750ms ease-in-out' ,fontFamily:Theme.header.fontFamily,fontSize:Theme.button.fontSize,color:props.buttonColor? 'white' : 'black'}}><div style={{display:"flex",width:"100%",flexFlow:'column',justifyContent:"center",alignItems:"center"}}>{props.items[0].button} </div></Button>
                                            
                                            <Button onClick={()=>{
                                                props.scroller(1)
                                            //    props.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                                            }}  className={classes.buttonHover}    style={{ margin:"10px",border:' 1px solid whitesmoke',marginTop:'15px',height:"45px",borderRadius:'2px',backgroundColor:props.buttonColor? props.buttonColor :"rgba(255,255,255,1.0)",fontWeight:'900',minWidth:'15vw',maxWidth:'120px',position:'relative',flex:1,bottom:current === index ? '-1vh':'-50vh', transitionDelay:'500ms',transition:'all 1000ms ease-in-out' ,fontFamily:Theme.header.fontFamily,fontSize:Theme.button.fontSize,color:props.buttonColor? 'white' : 'black'}}><div style={{display:"flex",width:"100%",flexFlow:'column',justifyContent:"center",alignItems:"center"}}>{props.items[1].button}</div> </Button>
                                        
                                        <Button onClick={()=>{
                                                props.scroller(2)
                                            //    props.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                                            }} className={classes.buttonHover}  style={{ margin:"10px",border:' 1px solid whitesmoke',marginTop:'15px',height:"45px",borderRadius:'2px',backgroundColor:props.buttonColor? props.buttonColor :"rgba(255,255,255,1.0)",fontWeight:'900',minWidth:'15vw',maxWidth:'120px',position:'relative',flex:1,bottom:current === index ? '-1vh':'-50vh', transitionDelay:'500ms',transition:'all 1250ms ease-in-out' ,fontFamily:Theme.header.fontFamily,fontSize:Theme.button.fontSize,color:props.buttonColor? 'white' : 'black'}}><div style={{display:"flex",width:"100%",flexFlow:'column',justifyContent:"center",alignItems:"center"}}>{props.items[2].button} </div></Button>
                                        {props.fourButton ?  <Button onClick={()=>{
                                                props.scroller(3)
                                            //    props.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                                            }} className={classes.buttonHover}  style={{ margin:"10px",border:' 1px solid whitesmoke',marginTop:'15px',height:"45px",borderRadius:'2px',backgroundColor:props.buttonColor? props.buttonColor :"rgba(255,255,255,1.0)",fontWeight:'900',minWidth:'15vw',maxWidth:'120px',position:'relative',flex:1,bottom:current === index ? '-1vh':'-50vh', transitionDelay:'500ms',transition:'all 1500ms ease-in-out' ,fontFamily:Theme.header.fontFamily,fontSize:Theme.button.fontSize,color:props.buttonColor? 'white' : 'black'}}><div style={{display:"flex",width:"100%",flexFlow:'column',justifyContent:"center",alignItems:"center"}}>{props.buttonText}</div></Button>
                                        : null}
                                    </div>
                                   </div>
                                   
                                </div>
                            </div>
                          
            })}
        </div>
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(Carousel) 

import React,{useState, useEffect,useRef} from 'react' 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux'; 
import useTheme from '../../Components/Theme/useTheme'
import CDiv from '../../Components/CDiv/CDiv'
import { useHistory } from "react-router-dom";
import firebase from 'firebase'
import Carousel  from '../../Components/Carousel/Carousel';
import Header from '../../Components/Header/Header';
import Title from '../../Components/Title/Title'; 
//for ios safari smooth scroll
import smoothscroll from 'smoothscroll-polyfill';  
import Text from '../../Components/Text/Text'
import SignupSelector from './SignupSelector';
import useWindowDimensions from '../../Components/useWindowDimensions';
import PromoView from '../../Components/PromoView/PromoView';
import Button from '../../Components/Button/Button' 
import {GiMeshNetwork} from 'react-icons/gi'
import {BiCameraMovie} from 'react-icons/bi'
import {AiOutlinePhone} from 'react-icons/ai' 
import Signup from '../../Components/Signup/Signup';
//for ios safari smooth scroll
window.__forceSmoothScrollPolyfill__ = true;
// kick off the polyfill!
smoothscroll.polyfill();
var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
const queryString = require('query-string');
const Att = (props) => {
    const Theme = useTheme();  
    const {height,width} = useWindowDimensions()
    let history = useHistory();
    const [firstLoad, setFirstLoad]            = useState(true) 
    const [selectedFiber,setSelectedFiber]     = useState(false)
    const [selectedCable,setSelectedCable]     = useState(false)
    const [hideBack,setHideBack]               = useState(false)
    const [selectedPhone,setSelectedPhone]     = useState(false)
    const [selectedWireless,setSelectedWireless]     = useState(false)
    const [employees,setEmployees]             = useState(null)
    const [user,setUser]                       = useState({name:'Sterling Group',email:''})
    const itemArray                            = [
                                                    {
                                                    name:'At&t Fiber',
                                                    button:'Fiber',
                                                    img:'https://firebasestorage.googleapis.com/v0/b/sterlinggroupsite.appspot.com/o/pexels-guillaume-meurice-3363556.jpg?alt=media&token=7d64d1ad-8b09-4374-a913-d8db412b4503',
                                                    intro:'How fast is fast enough?',
                                                    rest:'Speeds up to 1000 Mbps and beyond. ',
                                                    ref:''
                                                    }, 
                                                    {
                                                    name:'At&t TV',
                                                    button:'TV',
                                                    img:'https://images.pexels.com/photos/1040158/pexels-photo-1040158.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
                                                    intro:'Never miss the game with At&t premier packages.',
                                                    rest:''
                                                    },
                                                    {
                                                    name:'At&t Phone',
                                                    button:'Phone',
                                                    img:'https://images.pexels.com/photos/7113753/pexels-photo-7113753.jpeg?cs=srgb&dl=pexels-cottonbro-7113753.jpg&fm=jpg',
                                                    intro:' ',
                                                    rest:''
                                                    },
                                                    {
                                                    name:'Signup Today',
                                                    button:'Signup',
                                                    img:'https://images.pexels.com/photos/955389/pexels-photo-955389.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
                                                    intro:'At&t Fiber, Cable & Phone ',
                                                    rest:'Bundle all three and save! '
                                                    }
                                                ]
    const scroll1 = useRef() 
    const scroll2 = useRef()
    const scroll3 = useRef()
    const scroll4 = useRef()
    useEffect(()=>{
        let listener = null
        if(firstLoad){ 
            let params = queryString.parse(history.location.search) 
            const currUrl =  params.user
                
                if(currUrl){ 
                    setHideBack(true)
                    firebase.firestore().collection('employees').doc(currUrl).get().then(doc=>{
                        scroll4.current.scrollIntoView({behavior:'smooth'})
                        // props.setShowModal(true)
                        setUser({...doc.data()})
                        // props.setModalContent(<ViewUser alert={true} closeModal={()=>{props.setShowModal(false)}} user={{...doc.data()}} /> )
                        
                    })
                  
                } else {
                    setHideBack(false)
                    setUser({...user,email:Theme.main.siteEmail}) 
                   
                } 
                    
            setFirstLoad(false)
            props.setCurrentName("AT&T Services")
        }
        return ()=>{
                if(listener){
                    listener()
                } 
              
        }
    },[user,selectedFiber,selectedCable,selectedPhone,setSelectedWireless,props.employees,props.data])
    useEffect(()=>{
        return ()=>{
            setSelectedCable(false)
            setSelectedFiber(false)
            setSelectedPhone(false)
        }
    },[])
    const scrollOne = (index) =>{
        setTimeout(()=>{
            switch(index){
                case 0:
                    scroll1.current.scrollIntoView({behavior:'smooth'})
                break;
                case 1:
                    scroll2.current.scrollIntoView({behavior:'smooth'})
                break;
                case 2:
                    scroll3.current.scrollIntoView({behavior:'smooth'})
                break;
                case 3:
                    scroll4.current.scrollIntoView({behavior:'smooth'})
                break;
        }
        },500)
        
       
    }
    return (
    <CDiv  style={{backgroundImage:Theme.main.backgroundImage,backgroundRepeat:'no-repeat',backgroundPosition:'center',top:0}}>  
        <Carousel fourButton={true} buttonText="Signup" buttonColor={Theme.main.colorTertiary}  scroller={(index)=>scrollOne(index)}  width={'100vw'}  height={props.deviceType === "mobile" ? '88vh' :'100vh'} items={itemArray}/>
        <div ref={scroll1} style={{minHeight:"100vh",width:"100%",paddingBottom:'5vh',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",flexFlow:'column'}} > 
            <GiMeshNetwork style={{fontSize:'100px',padding:'0px',marginTop:'8vh',transitionDuration:"500ms",color:Theme.main.colorTertiary}}/>
            <Header style={{color:Theme.main.colorTertiary,marginBottom:'1vh'}}>At&t Internet</Header> 
            {/* insert 3 download buttons here */}
            <div style={{display:'flex',flexFlow:'column',width:'100%',justifyContent:'center',alignItems:'center',marginBottom:'7vh'}}>
            { (props.data && props.data.att.section1 && props.data.att.section1.link1 && props.data.att.section1.link1 !== '' )||
           (props.data && props.data.att.section1 && props.data.att.section1.link2 && props.data.att.section1.link2 !== '') ||
           (props.data && props.data.att.section1 && props.data.att.section1.link3 && props.data.att.section1.link3 !== '') ? <Title style={{color:Theme.main.colorTertiary}}>View Our Catalogs</Title> : null } 
                <div style={{display:'flex',flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                {props.data && props.data.att.section1 && props.data.att.section1.link1 && props.data.att.section1.link1 !== '' ?   <Button click={()=>{window.open(props.data.att.section1.link1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 1</Button> : null }
                {props.data && props.data.att.section1 && props.data.att.section1.link2 && props.data.att.section1.link2 !== '' ?   <Button click={()=>{window.open(props.data.att.section1.link2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 2</Button> : null }
                {props.data && props.data.att.section1 && props.data.att.section1.link3 && props.data.att.section1.link3 !== '' ?   <Button click={()=>{window.open(props.data.att.section1.link3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 3</Button> : null }        
                </div>
            </div>
            <div style={{width:'100%',height:'auto',display:'flex', flexFlow:'row',flexWrap:'wrap'}}>
                <PromoView item={props.data && props.data.att.section1 && props.data.att.section1.product0 ? {...props.data.att.section1.product0}:null} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedFiber(true);}}/> 
                <PromoView item={props.data && props.data.att.section1 && props.data.att.section1.product1 ? {...props.data.att.section1.product1}:null} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedFiber(true);}}/>
                <PromoView  item={props.data && props.data.att.section1 && props.data.att.section1.product2 ? {...props.data.att.section1.product2}:null} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedFiber(true);}}/>
                <PromoView  item={props.data && props.data.att.section1 && props.data.att.section1.product3 ? {...props.data.att.section1.product3}:null} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedFiber(true);}}/>
            </div>
        </div>  
        <div ref={scroll2}  style={{minHeight:"100vh",width:"100%",paddingBottom:'5vh',backgroundColor:Theme.main.colorTertiary,display:"flex",justifyContent:"center",alignItems:"center",flexFlow:'column'}} > 
            <BiCameraMovie style={{fontSize:'100px',padding:'0px',marginTop:'8vh',transitionDuration:"500ms",color:'white'}}/>
            <Header style={{color:'white',marginBottom:'1vh' }}>Stream it with At&t TV</Header>
            <Header style={{color:'white',marginBottom:'1vh' }}>or</Header>
            <Header style={{color:'white',marginBottom:'1vh' }}>Beam it with At&t Direct TV</Header>
            <div style={{display:'flex',flexFlow:'column',width:'100%',justifyContent:'center',alignItems:'center',marginBottom:'7vh'}}>
            { (props.data && props.data.att.section2 && props.data.att.section2.link1 && props.data.att.section2.link1 !== '' )||
           (props.data && props.data.att.section2 && props.data.att.section2.link2 && props.data.att.section2.link2 !== '') ||
           (props.data && props.data.att.section2 && props.data.att.section2.link3 && props.data.att.section2.link3 !== '') ? <Title style={{color:'white'}}>View Our Catalogs</Title> : null }
                <div style={{display:'flex',flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                {props.data && props.data.att.section2 && props.data.att.section2.link1 && props.data.att.section2.link1 !== '' ?   <Button click={()=>{window.open(props.data.att.section2.link1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 1</Button> : null }
                {props.data && props.data.att.section2 && props.data.att.section2.link2 && props.data.att.section2.link2 !== '' ?   <Button click={()=>{window.open(props.data.att.section2.link2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 2</Button> : null }
                {props.data && props.data.att.section2 && props.data.att.section2.link3 && props.data.att.section2.link3 !== '' ?   <Button click={()=>{window.open(props.data.att.section2.link3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 3</Button> : null }        
                </div>
            </div>
            <div style={{width:'100%',height:'auto',display:'flex',flexFlow:'row',flexWrap:'wrap'}}> 
                <PromoView item={props.data && props.data.att.section2 && props.data.att.section2.product1 ? {...props.data.att.section2.product1}:null} invert={true} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedCable(true);}}/>
                <PromoView item={props.data && props.data.att.section2 && props.data.att.section2.product2 ? {...props.data.att.section2.product2}:null}  invert={true} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedCable(true);}}/>
                <PromoView item={props.data && props.data.att.section2 && props.data.att.section2.product3 ? {...props.data.att.section2.product3}:null}  invert={true} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedCable(true);}}/>
                <PromoView item={props.data && props.data.att.section2 && props.data.att.section2.product4 ? {...props.data.att.section2.product4}:null}  invert={true} click={()=>{scroll4.current.scrollIntoView({behavior:'smooth'});setSelectedCable(true);}}/>
            </div>
        </div>  
        <div ref={scroll3}  style={{minHeight:"100vh",width:"100%",paddingBottom:'5vh',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",flexFlow:'column'}} > 
            <AiOutlinePhone style={{fontSize:'100px',padding:'0px',marginTop:'8vh',transitionDuration:"500ms",color:Theme.main.colorTertiary}}/>
            <Header style={{color:Theme.main.colorTertiary,marginBottom:'1vh' }}>At&t Wireless & Phone</Header>
            <div style={{display:'flex',flexFlow:'column',width:'100%',justifyContent:'center',alignItems:'center',marginBottom:'7vh'}}>
           { (props.data && props.data.att.section3 && props.data.att.section3.link1 && props.data.att.section3.link1 !== '' )||
           (props.data && props.data.att.section3 && props.data.att.section3.link2 && props.data.att.section3.link2 !== '') ||
           (props.data && props.data.att.section3 && props.data.att.section3.link3 && props.data.att.section3.link3 !== '') ? <Title style={{color:Theme.main.colorTertiary}}>View Our Catalogs</Title> : null }
                <div style={{display:'flex',flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                {props.data && props.data.att.section3 && props.data.att.section3.link1 && props.data.att.section3.link1 !== '' ?   <Button click={()=>{window.open(props.data.att.section3.link1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 1</Button> : null }
                {props.data && props.data.att.section3 && props.data.att.section3.link2 && props.data.att.section3.link2 !== '' ?   <Button click={()=>{window.open(props.data.att.section3.link2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 2</Button> : null }
                {props.data && props.data.att.section3 && props.data.att.section3.link3 && props.data.att.section3.link3 !== '' ?   <Button click={()=>{window.open(props.data.att.section3.link3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'7px',borderRadius:'2px',margin:'5px'}}   >Catalog 3</Button> : null }        
                </div>
            </div>
            <div style={{display:'flex',flexFlow:"row",height:'auto',flexWrap:"wrap",marginBottom:"10vh",justifyContent:'center',alignItems:"center"}}>
                <div style={{display:'flex',overflow:"hidden",flexFlow:"column"  ,justifyContent:'center',alignItems:"center",padding:"25px"}}>
                    <img   style={{borderRadius:'6px',width:width<(Theme.main.breakPoint + 200) ? '95vw':'45vw',height:width<(Theme.main.breakPoint + 200) ? '55vw':'25vw',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)',marginTop:'0vh',marginBottom:'3vh'}}    src={props.data && props.data.att && props.data.att.section3 ? props.data.att.section3.img :null} title="At&T Phone" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></img>
                </div> 
                {width < Theme.main.breakPoint+200 ? <hr style={{width:'86%',height:'1px',marginBottom:'5vh',backgroundColor:Theme.main.colorTertiary,border:"1px solid "+Theme.main.colorTertiary}}></hr> : <hr style={{width:'0px',height:'70vh',backgroundColor:Theme.main.colorTertiary,border:"1px solid "+Theme.main.colorTertiary}}></hr>} 
                <div style={{display:'flex',flexFlow:"column",padding:"15px",width:width<(Theme.main.breakPoint + 200)? '85%':'45%',height:'auto' ,flexWrap:"wrap",justifyContent:'center',alignItems:"center"}}>
                    <Header style={{color:Theme.main.colorTertiary}}>{props.data && props.data.att && props.data.att.section3 ? props.data.att.section3.text :null}</Header> 
                </div>  
            </div> 
        </div> 
        <div ref={scroll4}  style={{minHeight:"auto" ,width:"100%",backgroundColor:Theme.main.colorTertiary,display:"flex",justifyContent:"flex-start",overflow:"hidden" ,position:'relative',flexFlow:'column' }} > 
            <Header style={{color:'white',marginTop:'5vh',marginBottom:'-6vh'}}>At&t</Header> 
            <Header style={{color:'white',marginTop:'0vh',marginBottom:'-2vh'}}>Signup Today!</Header> 
        
            {user ? <div style={{width:"100%",display:'flex',flexFlow:'column'}}><Title style={{color:"white",marginLeft:"5px",marginRight:'5px',padding:'0px',textAlign:'center'}}>{user.name } welcomes you to the At&t family!</Title><Text style={{color:"white",padding:"0px",textAlign:'center'}}>To continue, please select the services you are interested in.</Text></div>:<div><Title style={{color:"white",paddingLeft:"5px",paddingRight:'5px',textAlign:'center'}}>Please Select your At&t representative.</Title><Text style={{color:"white",padding:"0px",textAlign:'center'}}>Dont have one? No problem, <b>select <i>No Rep</i></b>.</Text></div>}
            <div style={{position:"relative",display:'flex',flexFlow:'column',margin:'auto',top:user ?'-0px':'0px'}}> 
                <Button click={true  ? ()=>{if((selectedFiber || selectedCable || selectedPhone || selectedWireless)){props.setModalContent(<Signup userlocal={user} items={[selectedFiber,selectedCable,selectedPhone,selectedWireless]} type='ATT'/>);props.setShowModal(true);}else{window.alert('Please Select a Service Below.')}}:()=>{}} styler={{opacity:true && user?1:0,backgroundColor:"white",width:"250px",fontWeight:'900',border:'none',minHeight:'auto',height:"auto",minWidth:"250px",margin:'auto',marginTop:"25px",boxShadow:'0px 0px 20px white',borderRadius:'0px',padding:'20px',transitionDuration:"1s"}}>Signup</Button>  
            </div>  
            <div style={{width:"100%",marginBottom:"0vh",overflow:"hidden",transform: true ? 'translateY(30px)' :'translateY(-4vh)' ,height:"auto",minHeight:'70vh',overflowY:'hidden',transitionDuration:'1s',position:'relative',display:"flex",marginTop:'0px',justifyContent:"center",alignItems:'center',flexWrap:'wrap'}}>
                <SignupSelector selected={selectedFiber} setSelected={(e)=>{setSelectedFiber(!selectedFiber); }} type={0}/>
                <SignupSelector selected={selectedCable} setSelected={(e)=>{setSelectedCable(!selectedCable); }}  type={1}/>
                <SignupSelector  selected={selectedPhone} setSelected={(e)=>{setSelectedPhone(!selectedPhone); }}  type={2}/> 
                <SignupSelector  selected={selectedWireless} setSelected={(e)=>{setSelectedWireless(!selectedWireless); }}  type={5}/> 
            </div> 
        </div>  
   </CDiv> 
    )
}

const mapStateTopProps = state =>{
    return {...toolbarStateToProps(state) }
}
const mapDispatchToProps = dispatch =>{
    return {...toolbarDispatchToProps(dispatch) }
}
export default connect(mapStateTopProps,mapDispatchToProps)(Att) 

import React, { useEffect } from 'react'
import useTheme from '../Theme/useTheme'
import useWindowDimensions from '../useWindowDimensions'
import {AiOutlineMenu} from 'react-icons/ai'  
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import Title from '../../Components/Title/Title'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Header from '../Header/Header'
import Text from '../Text/Text'
const Toolbar = (props) => {
    const {height, width} = useWindowDimensions();  
    const history = useHistory()
    const Theme = useTheme(props.scroll);
    useEffect(() => {
        if(width > Theme.main.breakPoint){ 
            props.setToolbarDisplay(<div style={{display:'flex',flexFlow:'row',height:'auto',width:'100%',marginRight:'4px' }}><img onDoubleClick={()=>{history.push("/Login")}}   src={Theme.main.backgroundImage} style={{width:'6vh',backgroundColor:props.scroll? 'whitesmoke':'whitesmoke',height:'6vh',borderRadius:'4px',boxShadow:'0px 0px 2px '+Theme.main.colorSecondary,marginLeft:'20px',alignSelf:'center'}} ></img><h5  style={{color:'whitesmoke' ,marginTop:'6px',marginLeft:'5px',alignSelf:'center',marginRight:'auto',textShadow:'0px 0px 3px black',...Theme.toolbarLinks,marginTop:"auto",marginBottom:'auto',marginRight:"auto"}}>Sterling Group</h5>{props.getLinks()}</div>)  
        } else {  
            props.setToolbarDisplay(<div style={{opacity:props.showMenu ? 0:1,transitionDelay:props.showMenu? '0ms':'1250ms',transitionDuration:'500ms',color:Theme.main.colorSecondary,fontSize:Theme.toolbarLinks.fontSize,width:'100vw',flexFlow:'row',display:'flex',alignItems:'center'}}>
                                <AiOutlineMenu style={{  padding:'0px',height:'6vh',width:'6vh',margin:'5px',marginLeft:'10px',borderRadius:'4px' ,color:'whitesmoke',textShadow:'0px 0px 3px black'}} onClick={()=>{props.setShowMenu(props.showMenu)}}/>
                                <h5  style={{marginTop:'6px',marginLeft:'5px',alignSelf:'center',marginRight:'auto',textShadow:'0px 0px 1px black',...Theme.toolbarLinks,marginTop:"auto",marginBottom:'auto',marginRight:"auto",color:props.scroll? Theme.main.colorPrimary:'whitesmoke' }}>{props.currentName}
                                </h5>
                                 <img onDoubleClick={()=>{history.push("/Login")}} onClick={()=>{props.setShowMenu(props.showMenu)}}  src={Theme.main.backgroundImage} style={{ backgroundColor:'white' ,marginRight:'10px',height:'6vh',width:'6vh',borderRadius:'4px',marginLeft:'auto'}} ></img>
                                </div>)
        }
    }, [width,props.currentName,props.auth,props.showMenu,props.auth ,props.scroll])
    
   
    return (
        <div style={{zIndex:10,overflowX:'hidden',top:0,overflowY: 'hidden',position:'fixed',top:0,left:0,flexDirection:'row',justifyContent:'flex-end',alignItems:'center',display:'flex',transition:'height 1.00s ease-in-out',height:props.scroll? '7vh' : '10vh',width:'100vw',backgroundColor: props.scroll? Theme.main.colorTertiary :'rgba(0,0,0,0.0)',boxShadow:props.scroll? "0px 0px 2px "+Theme.main.colorSecondary:''}}>
            {props.toolbarDisplay} 
        </div>
    )
} 
const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(Toolbar)

import React ,{useEffect} from 'react'
import useTheme from '../../Components/Theme/useTheme'
import Title from '../../Components/Title/Title'
import Text from '../../Components/Text/Text'
import firebase from 'firebase'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";   
import { connect } from 'react-redux'; 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import EditEmployee from './EditEmployee'
import EditUser from './EditUser' 
import ViewUser from './ViewUser'
import { AiOutlinePropertySafety } from 'react-icons/ai'
var QRCode = require('qrcode.react');
const Employee = (props) => {
    const Theme= useTheme()
  
    return (
        <div onClick={(e)=>{
            props.setModalContent(props.public ? <ViewUser  closeModal={()=>{props.setShowModal(false)}} user={props.employee} /> : <EditUser  closeModal={()=>{props.setShowModal(false)}} user={props.employee}/>)
            props.setShowModal(true)
        }} style={{display:'flex',margin: '5px',marginTop:'10px',marginBottom:'10px',flexFlow:"column",padding:'5px',width:'150px',height:'auto',minHeight:'175px',maxHeight:'40vh',overflow:'hidden',justifyContent:'center',alignItems:'center',borderRadius:'2px',boxShadow:'0px 1px 3px rgba(0,0,0,0.6)',backgroundColor:'rgba(255,255,255,0.85)'}}>
            {props.public?  <Text style={{fontWeight:"900",marginTop:"8px",marginBottom:'17px'}}>{props.employee.name}</Text> :<div style={{width:'100%',flex:'center',flexFlow:'column',justifyContent:'center',alignItems:'center'}}> <Text  style={{textAlign:'center'}}  >Employee # {props.index}</Text>
            <Text style={{textAlign:'center'}}>{props.employee.name}</Text></div> }
           
            <img src={props.employee.img} style={{width:'90%',height:"auto",margin:'5px',marginBottom:'8px',flex:1,borderRadius:'50%',boxShadow:!props.public?'0px 3px 5px grey':Theme.main.boxShadow}}></img>
            <div style={{display:'flex',flexFlow:"column",justifyContent:'center',alignItems:'center',flex:1}}>
           
            </div>
        </div>
    )
}


const mapStateTopProps = state =>{
    return { ...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
    return { ...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(Employee) 

import { colors } from '@material-ui/core';
import { useState, useEffect } from 'react';
import useWindowDimensions from '../useWindowDimensions'  
function useTheme(scroll) {
    
   const BREAK_POINT = 700;
   const TRANSITION_DURATION = '500ms'
   const colorSecondary = '#00A8H9'

   const colorTertiary = '#00A8F9'
   const {height,width} = useWindowDimensions(); 
   const mainTheme = {
      main:{marginBottom:'5%',marginTop:'5%',colorPrimary:'whitesmoke',siteEmail:'sterlingcommunicationsinc8@gmail.com',colorSecondary:colorSecondary,colorTertiary:colorTertiary,colorShadow:'grey',borderRadius:'4px',boxShadow:'-1px -1px 10px rgba(0,0,0,0.3)',breakPoint:BREAK_POINT,backgroundImage:'https://firebasestorage.googleapis.com/v0/b/sterling-d7fb4.appspot.com/o/images%2Flogo.png?alt=media&token=6df2a79a-aa38-415e-8803-607eae651637'},
      title:{fontFamily:'Farro, sans-serif',fontWeight:'100',fontSize:'28px',color:'black',transitionDuration:TRANSITION_DURATION,textAlign:'left'},
      header:{fontFamily:'Farro, sans-serif',textAlign:'center',fontSize:'70px',color:colorSecondary,fontWeight:'900',letterSpacing:'0px',transitionDuration:TRANSITION_DURATION ,padding:'20px'},
      text:{fontFamily:'Farro, sans-serif',textIndent:'0px',fontSize:'15px',color:'black',transitionDuration:TRANSITION_DURATION,textAlign:'left'   },
      basicCenter:{display:'flex',justifyContent:'center',overflowX:'hidden',alignItems:'center' ,width:'100%',flexFlow:'column',overflowY:'auto',transitionDuration:TRANSITION_DURATION},
      boxContainer:{backgroundColor:'white',border:'1px solid black',borderRadius:'2px',minHeight:'20vh',minWidth:'97%',margin:'10px',transitionDuration:TRANSITION_DURATION,display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center',padding:'10px'},
      toolbarLinks:{fontFamily:'Farro, sans-serif',letterSpacing:'0px',fontSize:'18px'  ,color: 'whitesmoke', textDecoration:'none',width:'auto',transitionDuration:TRANSITION_DURATION},
      stylePageMainDiv:{top:'0',backgroundColor:'white',width:'100%',position:'relative',display:'block',flexFlow:'column',justifyContent:'center',alignItems:'center',overflowY:'auto',overflowX:'hidden',transitionDuration:TRANSITION_DURATION},
      button:{fontFamily:'Farro, sans-serif',opacity:1,backgroundColor:"white",width:"250px",fontWeight:'900',border:'none',minHeight:'auto',height:"auto",minWidth:"250px",margin:'auto' ,color:colorTertiary,boxShadow:'0px 0px 10px rgba(0,0,0,0.35)',borderRadius:'0px',padding:'20px',transitionDuration:"1s"},
      card:{fontFamily:'Farro, sans-serif',opacity:1,backgroundColor:"white",width:"250px",fontWeight:'900',border:'none',minHeight:'auto',height:"auto",minWidth:"250px",margin:'auto' ,color:colorTertiary,boxShadow:'0px 0px 10px rgba(0,0,0,0.35)',borderRadius:'0px',padding:'20px',transitionDuration:"1s"},
      smallDelete:{color:'red',alignSelf:'center'}
}
   const [theme,setTheme] = useState({...mainTheme})
   useEffect(()=>{
      if(width >= BREAK_POINT){ 
         setTheme({...mainTheme}) 
      } else { 
         setTheme({ ...theme,
            title:{...theme.title, fontSize:'22px'},
            header:{...theme.header,fontSize:'40px'},
            text:{...theme.text,fontSize:'13px'}, 
            boxContainer:{...theme.boxContainer,minWidth:'90%',margin:'6px',padding:'6px'},
            toolbarLinks:{...theme.toolbarLinks,fontSize:'20px' }, 
            button:{...theme.button,padding:'10px',width:'auto',minWidth:'150px' , fontSize:'14px' }
      })
   } 
   },[width,scroll])    
  return theme;
} 
 
export default  (useTheme);
import React , {useState,useEffect,useRef} from 'react'
import Header from '../../Components/Header/Header'
import TextField from '@material-ui/core/TextField';
import Button from '../../Components/Button/Button'; 
import firebase from 'firebase'
import useTheme from '../../Components/Theme/useTheme';
import Loader from 'react-loader'  
import Resizer from 'react-image-file-resizer';
import ImageCropper from '../../Components/ImageCropper/ImageCropper'
import Text from '../../Components/Text/Text'
import Title from '../../Components/Title/Title'
import { ThemeProvider } from '@material-ui/core';
import BizCard from './BizCard';
import ModalDivWrapper from '../../Components/ModalDivWrapper/ModalDivWrapper'; 
const ViewUser = (props) => {
    const [newData, setNewData] =useState(null) 
    const Theme = useTheme() 
    useEffect(() => {
        if(newData === null){
            
            setNewData({...props.user})
        }else{
             
        }
        
    }, [newData,props.data,props.user])
     
    return (
      <ModalDivWrapper style={{backgroundColor:Theme.main.colorTertiary,padding:'20px',height:'88%'}}>
                    <div style={{ margin:'5px' ,width:"35vw",minWidth:"400px", display:"flex",flexFlow:'column',justifyContent:'center',alignItems:"center"}}> 
                            <Title style={{fontSize:props.alert ? '20px ' :'36px',color:"white",borderRadius:'8px',width:"85%", textAlign:'center',marginBottom:'25px' ,padding:'10px',minHeight:'30px' ,fontWeight:'700',textAlign:'center'}}>{props.alert ?   " Welcome to our At&t network! Looking forward to hearing from you "  + " -"+ props.user.name : props.user.name}</Title> 
                            <img   style={{borderRadius:'2px',width:'85%',marginLeft:'2px',height:'auto',marginBottom:'25px',boxShadow:'0px 0px 20px rgba(0,0,0,0.55)'}} src={newData? newData.img : props.user.img} />
                    </div>
                     <BizCard   userIn={{...newData,id:props.user.id}}/>
    </ModalDivWrapper>
    )
}

export default ViewUser

import React,{useEffect,useState} from 'react'
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import { connect } from 'react-redux'; 
import CDiv from '../CDiv/CDiv'
import classes from './Modal.module.css'
import useTheme from '../Theme/useTheme'
import useWindowDimensions from '../useWindowDimensions'
import { AiFillCloseCircle } from 'react-icons/ai';
const Modal = (props) => { 
    const Theme = useTheme()
    const {height, width} = useWindowDimensions()
    const [padBottom,setPadBottom] = useState(false)
    useEffect(()=>{
        let subText = window.navigator.platform.substring(0,5)  
        if(window.navigator.platform === 'iPhone' ){
            setPadBottom(true)
        } else if(subText === 'Linux') { 
            setPadBottom(true) 
        } else {
            setPadBottom(false)
        }
    },[props.showModal])
    return ( 
    <div onClick={(e)=>{
        props.setShowModal(false)
        e.stopPropagation()
    }}  className={classes.Modal}
        style={{ transform: props.showModal ? 'translateY(0)'  : 'translateY(100vh)',transitionDuration:'500ms',display:'flex',
        opacity:  props.showModal ? '1':'0' ,height:"100%",width:'100%',position:'absolute',justifyContent:'center',alignItems:'center'
    }}>
         <div onClick={(e)=>{
               e.stopPropagation()
         }} style={{...Theme.stylePageMainDiv,boxShadow:Theme.main.boxShadow,top:'2vh', margin:'auto',position:'fixed',minWidth: width < Theme.main.breakPoint ? '90%' :'65%',height:'auto',height:padBottom ?'82vh':'90vh',width:'auto' ,maxWidth:'90vw' ,boxShadow:'0px 0px 5px slategrey',alignSelf:'center',padding:'2px',borderRadius:'2px',backgroundColor:"whitesmoke"}}> 
            <div style={{alignContent:'center',fontSize:"32px",boxShadow:"0px 0px  4px rgba(0,0,0,0.5)",justifyContent:'flex-end',display:'flex',width:"100.25%",position:'aboslute'}}>
            <AiFillCloseCircle onClick={(e)=>{props.setShowModal(false)}} style={{zIndex:"30"}} />
            </div>
            
            {props.modalContent}  
        </div> 
        </div> 
    )
}

const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(Modal)

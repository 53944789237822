import Types from '../Types'
const initialState = { 
    auth:false,
    user:null,
    showMenu:false,
    currentName:'Home', 
    toolbarDisplay:null,
    modalContent:null,
    showModal:false,
    scroll:false,
    employees:[],
    work:null,
    navigationShow:true,
    scrollTop:200,
    deviceType:'desktop',
    data:null
}
const toolbarR  = (state = initialState, action) =>  { 
    if(action.type === Types.setShowMenu){
        const show = !action.value
        return {
            ...state,
            showMenu:show
        }
    }
    if(action.type === Types.setShowMenuManual){
        return {
            ...state,
            showMenu:action.value,
            navigationShow:true
        }
}
    if(action.type === Types.setAuth){
        return {
            ...state,
            auth:action.value
        }
    }
    if(action.type === Types.setUser){ 
        return {
            ...state,
            user:action.value 
        }
    }
    if(action.type === Types.setCurrentName){
        return {
            ...state,
            currentName:action.value
        }
    } 
    if(action.type === Types.setToolbarDisplay){
        return {
            ...state,
            toolbarDisplay:action.value
        }
    } 
    if(action.type === Types.setShowModal){
        const show = action.value
        if(show){
            return {
                ...state,
                showModal:show
            }
        } else {
            return {
                ...state,
                showModal:show,
                modalContent:null
            }
        }
       
    } 
    if(action.type === Types.setModalContent){
        const newCont = {...action.value}
        return {
            ...state,
            modalContent:newCont
        }
    } 
    if(action.type === Types.setScroll){
        const scroll = action.value
        return {
            ...state,
            scroll:scroll
        }
    } 
    if(action.type === Types.setEmployees){
        const emps = [...action.value]
        return {
            ...state,
            employees:emps
        }
    } 
    if(action.type === Types.setWork){
        const work = [...action.value]
        return {
            ...state,
            work:work
        }
    } 
    if(action.type === Types.setNavigation){
        const nav = action.value
        return {
            ...state,
            navigationShow:nav
        }
    } 
    if(action.type === Types.setScrollTop){
        const top = action.value
        return {
            ...state,
            scrollTop:top
        }
    }  
    if(action.type === Types.setDeviceType){
        const type = action.value
        return {
            ...state,
            deviceType:type
        }
    } 
    if(action.type === Types.setData){
        const data = {...action.value}
        return {
            ...state,
            data:data
        }
    } 
    return state;
}
export default toolbarR; 
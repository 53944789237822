import React from 'react'

const ModalDivWrapper = (props) => {
    const styler = {display:'block',flexFlow:'column',height:"95%",margin:'-1px',marginTop:'2px',overflowY:"auto",overflowX:"hidden",justifyContent:'center',alignItems:"center"}
    return (
    <div style={props.style ? {...styler,...props.style}:{...styler}}>  
            <div style={{width:"100%",minHeight:"95%",marginBottom:"4vh",display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
                {props.children}
            </div>
    </div>
    )
}

export default ModalDivWrapper

import React ,{useRef,useCallback,useEffect} from 'react'
import Text from '../../Components/Text/Text'
import useTheme from '../../Components/Theme/useTheme';
import useWindowDimensions from '../../Components/useWindowDimensions';
import ReactToPdf from 'react-to-pdf'
import Button from '../../Components/Button/Button'
import { toPng } from 'html-to-image';

import { connect } from 'react-redux'; 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import { QRCode } from 'react-qrcode-logo';
// var QRCode = require('qrcode.react'); 
const BizCard = (props) => {
    const Theme = useTheme() 
    const {height,width} = useWindowDimensions()
    const ref = React.createRef();
    const ref2 = React.createRef();
    const qrRef = React.createRef();
    const options = {
        orientation: 'horizontal',
        unit: 'px',
        format: [192,230]
    };

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = props.userIn ? props.userIn.name + '_sterling_front.png':'sterling_front.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [props.userIn,ref])
  const onButtonClick2 = useCallback(() => {
    if (ref2.current === null) {
      return
    }

    toPng(ref2.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = props.userIn ? props.userIn.name + '_sterling_back.png':'sterling_back.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref2,props.userIn])
  const onButtonClick3 = useCallback(() => {
    if (qrRef.current === null) {
      console.log("is null")
      return
    }

    toPng(qrRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = props.userIn ? props.userIn.name + '_sterling_QR.png':'sterling_QR.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [qrRef,props.userIn])
  
  useEffect(()=>{ },[ref,ref2,qrRef,props.data])
    return (
        <div>
                     
            <div style={{width:"auto",display:'flex',margin:'5px',marginTop:width < Theme.main.breakPoint ? '20px' :'75px',marginBottom:'0px',paddingBottom:'0px',flexFlow:"column",flexWrap:'wrap',justifyContent:'center',maxWidth:"350px",alignItems:'space-between',overflow:'hidden'}}>
                    <div ref={ref}  style={{display:"flex",minWidth:"332px",backgroundColor:"white",height:"190px",margin:'auto',marginTop:'auto',marginBottom:'auto', backgroundPositionX:'-10%',backgroundPositionY:'20%',justifyContent:"center",     border:'solid grey 1px',alignItems:'center',boxShadow:'0px 0px 5px rgba(0,0,0,0.35)'}}>
                    <div style={{display:"flex" ,margin:'auto',justifyContent:"center",alignItems:'center'}}>
                        <QRCode  logoImage={props.data.logo} qrStyle={'dots'} fgColor={'rgba(0, 168, 224,0.90)'}    eyeRadius={[ [0,90,0,90],  [90,0,90,0],    [90,0,90,0]]} ecLevel={"H"} size={150} logoWidth={150} logoOpacity={0.65} value={"https://sterlinggroup.life/AT&T%20Services?user="+props.userIn.id}  />
                         
                    </div>  
                    <div style={{display:"flex",backgroundColor:'whitesmoke',backgroundImage:'url('+Theme.main.backgroundImage+')',backgroundPositionX:'50%',backgroundPositionY:'-110%',borderRadius:"50%",margin:'auto',height:'180px',flexFlow:'column',width:"180px"  ,justifyContent:"center",alignItems:'center',boxShadow:'1px 1px 1px rgba(0,0,0,0.2)'}}>
                        <div style={{display:"flex",backgroundColor:'rgba(255,255,255,0.75)',borderRadius:"50%",margin:'auto',height:'180px',flexFlow:'column',width:"180px"  ,justifyContent:"center",alignItems:'center'}}>
                        <Text style={{textAlign:'center',fontWeight:'900',color:"black",marginBottom:'5px',fontSize:'18px'}}>Sterling Group</Text>
                            <Text style={{textAlign:'center',color:"black",fontSize:'14px',wordBreak:'break-word' }}>{props.userIn ? props.userIn.name:null}</Text>
                            <Text style={{textAlign:'center',color:"black",fontSize:'14px',wordBreak:'break-word',}}>{props.userIn ? props.userIn.email:null}</Text> 
                            <Text style={{textAlign:'center',color:"black",fontSize:'14px',wordBreak:'break-word'}}>{props.userIn ? props.userIn.phone:null}</Text>
                        </div>
                    </div>
                    </div>
                    <Button styler={{width:"auto",minWidth:'auto',height:"auto",minHeight:"auto",padding:'4px',marginBottom:'10px'}} click={onButtonClick}>Download</Button> 
                    <div ref={ref2}  style={{display:"flex",backgroundColor:"black",minWidth:"332px",height:"190px",margin:'0px',marginTop:'auto',marginBottom:'auto',backgroundImage:'url('+Theme.main.backgroundImage+')',backgroundPositionX:'40%',backgroundPositionY:'20%',justifyContent:"center", border:'solid grey 1px',backgroundColor:"black",alignItems:'center',marginTop:'-2px',boxShadow:'0px 0px 5px rgba(0,0,0,0.35)'}}>
                        
                        <div style={{display:"flex",flexFlow:'row',width:"100%",height:'100%',backgroundColor:'rgba(255,255,255,0.9)',justifyContent:"center",alignItems:'center'}}>
                   
                       <div style={{display:'flex',flex:1,flexFlow:"column",justifyContent:"center",alignItems:"center"}}>
                                <Text style={{textAlign:'center',color:"black",fontWeight:'900',fontSize:'16px',marginBottom:'1px',padding:"0px"}}>{props.userIn ? props.userIn.name:null}</Text>
                                <Text style={{textAlign:'center',color:"black",fontSize:'11px',padding:'2px'}}>{props.userIn ? props.userIn.bio:null}</Text>
                            </div>
                        </div>
                    </div>
                    <Button styler={{width:"auto",minWidth:'auto',height:"auto",minHeight:"auto",padding:'4px',marginBottom:'10px'}} click={onButtonClick2}>Download</Button> 
                    <div ref={qrRef}  style={{display:"flex",width:"340px",backgroundColor:"white",height:"auto",margin:'auto',marginTop:'auto',marginBottom:'auto'  ,padding:'0px',     border:'solid grey 1px' }}>
                          <QRCode  logoImage={props.data.logo} qrStyle={'dots'} fgColor={'rgba(0, 168, 224,0.90)'}    eyeRadius={[ [0,90,0,90],  [90,0,90,0],    [90,0,90,0]]} ecLevel={"H"} size={316} logoWidth={250} logoOpacity={0.65} value={"https://sterlinggroup.life/AT&T%20Services?user="+props.userIn.id}  />
                           
                    
                    </div>  
                    <Button styler={{width:"auto",minWidth:'auto',height:"auto",minHeight:"auto",padding:'4px',marginBottom:'10px'}} click={onButtonClick3}>Download</Button> 
            </div> 

          
        </div>
    )
}


const mapStateTopProps = state =>{
  return { ...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
  return { ...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(BizCard)  

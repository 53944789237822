import React, { useState,useEffect,useRef } from 'react'
import Toolbar from './Components/Navigation/Toolbar'
import  {Route, Switch, Redirect,useHistory} from 'react-router-dom'; 
import Home from './Pages/Home/Home' 
import UserHome from './Pages/UserHome/UserHome'
import Login from './Pages/Login/Login' 
import firebase from 'firebase'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";  
import Links from './Components/Navigation/Links' 
import SideDrawer from './Components/Navigation/SideDrawer'; 
import toolbarDispatchToProps from './Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from './Store/MapStateToProps/toolbarStateToProps'
import { connect } from 'react-redux'; 
import Modal  from './Components/Modal/Modal';  
import useThemee from './Components/Theme/useTheme' 
import Who from './Pages/Who/Who';
import Att from './Pages/Att/Att'
import Other from './Pages/Other/Other';
const Main = (props) => {  
    const history = useHistory()
    const Theme = useThemee()
    const [rootDiv,setRootDiv] = useState(null)
  
    useEffect(()=>{ 
        let listener = null; 
        let listener2 = null; 
        if(!props.data){
            listener = firebase.firestore().collection("data").doc('site')
            .onSnapshot((querySnapshot) =>{ 
                props.setData({...querySnapshot.data()})
            })
        }
        if(props.employees.length === 0){
            try{
                listener2 = firebase.firestore().collection("employees")
             .onSnapshot((querySnapshot) => {
             var setter = [];
             querySnapshot.forEach((doc) => { 
                 setter.push({...doc.data(),id:doc.id});
             });
             let sorted = setter.slice().sort((a, b) =>a.id - b.id);
            
           
             props.setEmployees(sorted)  

         });
             } catch(e){
                 console.log(e.message)
             } 
        }
        return()=>{
            if(listener){listener()} 
            if(listener2){listener2()} 
        }

    },[props.currentName,props.auth,props.showModal,rootDiv,props.data,props.employees]) 
    const getLinks = () => {
        return <>{props.auth ? <Links scroll={props.scroll} name='/Home' nameDisplay="Home" click={()=>{props.setCurrentName('Home');props.setShowMenuManual(false);}}/>
        :
        <Links scroll={props.scroll} name='/UserHome' nameDisplay="Home" click={()=>{props.setCurrentName('Home');props.setShowMenuManual(false);}}/>
        }  
         <Links scroll={props.scroll} name='/AT&T Services' nameDisplay="AT&T Services" click={()=>{props.setCurrentName('AT&T Services');props.setShowMenuManual(false);}}/>
         <Links scroll={props.scroll} name='/Our Employees' nameDisplay="Our Employees" click={()=>{props.setCurrentName('Our Employees');props.setShowMenuManual(false);}}/>
         <Links scroll={props.scroll} name='/Other Products' nameDisplay="Other Products" click={()=>{props.setCurrentName('Other Products');props.setShowMenuManual(false);}}/> 
       
        { props.auth ?  <Links scroll={props.scroll} name='/Logout' nameDisplay="Logout" click={()=>{logout();props.setCurrentName('Home')}}/>: null}
        </>
   }
   const logout = ()=>{
    firebase.auth().signOut().then(() => { 
        history.push("/Home") 
        props.setUser(null)
        props.setAuth(false)
        // props.setShowMenu(false)
      }).catch((error) => {
        // An error happened.
      }); 
    }
    firebase.auth().onAuthStateChanged(user=>{
        if(user){
            props.setAuth(true)
            props.setUser(user)
        }
    })
    return ( 
  
        <div  style={{height:'auto',width:'100vw',backgroundColor:Theme.main.colorTertiary }}>
               <Toolbar getLinks={getLinks}     />  
                <SideDrawer getLinks={getLinks}      />
                <Modal/> 
             <Switch   >   
                {props.auth ?<Route exact path="/Home" render={() =><UserHome /> } />:<Route exact path="/Home" render={() => <Home/> } />}
                <Route exact path="/Login" render={() =><Login/> } /> 
                <Route exact path="/AT&T Services" render={() =><Att/> } /> 
                <Route exact path="/Our Employees" render={() =><Who/> } /> 
                <Route exact path="/Other Products" render={() =><Other/> } /> 
                <Route exact path="/" render={() => (<Redirect to={"/Home"}/>)} /> 
                <Route exact path="/*" render={() => (<Redirect to={"/Home"}/>)} /> 
             </Switch>
        </div> 
    )
}
const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(Main)

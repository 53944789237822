import React, {useState,useEffect} from 'react'
import useTheme from '../../Components/Theme/useTheme' 
import Input from '@material-ui/core/Input';
import Button from '../../Components/Button/Button'
import { useHistory } from "react-router-dom";
import firebase from 'firebase/app'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";   
import { connect } from 'react-redux';
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import CDiv from '../../Components/CDiv/CDiv'
import Header from '../../Components/Header/Header'
const Login = (props) => { 
    const [userInput,setUserInput] = useState({username:'',password:''}) 
    const Theme = useTheme();
    const [firstLoad,setFirstLoad] = useState(true)
    const history = useHistory()
    const login = () =>{
        let allowLogin = true;
        if(userInput.username === ''){
            allowLogin = false
            alert("Please enter your email.")
        }
        if(userInput.password === ''){
            allowLogin = false
            alert("Please enter your password.")
        }
        if(allowLogin){
            
            firebase.auth().signInWithEmailAndPassword(userInput.username, userInput.password)
            .then((userCredential) => {
              // Signed in
              var user = userCredential.user; 
              props.setAuth(true)
              props.setUser(user)
              history.push('/Home')
              props.setCurrentName('Home')
              // ...
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              alert("Login Error: " + errorMessage)
            });
          
        }
    }
    
    const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          event.preventDefault();
          login()
          // callMyFunction();
        }
      };
    useEffect(() => { 
        props.setCurrentName("Login")
        if(firstLoad){
            setFirstLoad(false)
        }
        window.addEventListener("keydown", listener);
        return () => {
          window.removeEventListener("keydown", listener);
        };
    
      }, [window,firstLoad,userInput]);
    return (
        <CDiv>
        <Header>Login</Header>
        <div style={{...Theme.boxContainer}}>
        <div style={{width:'90%',position:'relative',alignItems:'center' ,display:'flex',flexFlow:'column'}}> 
        <Input  type='text' style={{width:'100%',margin:'10px'}} placeholder="Username/Email" value={userInput.username} onChange={(e)=>{setUserInput({...userInput,username:e.target.value})}} />
        <Input inputProps={{type:'password'}}  type='text' style={{width:'100%',margin:'10px'}} placeholder="Password" value={userInput.password} onChange={(e)=>{setUserInput({...userInput,password:e.target.value})}} />
        <Button style={{...Theme.button}} click={()=>{login()}} >Login</Button>
        </div>
        </div>
        </CDiv>
    )
}
const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(Login)

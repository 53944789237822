import React,{useEffect, useState,useRef} from 'react'
import useTheme from '../../Components/Theme/useTheme'  
import firebase from 'firebase'
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";  
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps'
import CDiv from '../../Components/CDiv/CDiv'
import Header from '../../Components/Header/Header'
import Title from '../../Components/Title/Title'
import Text from '../../Components/Text/Text'
import { connect } from 'react-redux';   
import { darken, makeStyles } from '@material-ui/core/styles';
import {TextField} from '@material-ui/core' 
import Employee from './Employee';
import Button from '../../Components/Button/Button';
var Loader = require('react-loader');
const UserHome = (props) => { 
    const Theme = useTheme();
    const [firstLoad,setFirstLoad] = useState(false)
    const [buttonText,setButtonText] = useState("Save Pages")
    const [translate,setTranslate] = useState(false)
    const [saveProgress,setSaveProgress] = useState(0);
    const [saving,setSaving] = useState(false)
    const imgs = useRef(null); 
    const imgs1 = useRef(null); 
    const imgs2 = useRef(null); 
    const imgs3 = useRef(null); 
    const imgs4 = useRef(null); 
    const imgs5 = useRef(null); 
    const imgs6 = useRef(null); 
    const imgs7 = useRef(null); 
    const imgs8 = useRef(null); 
    const array10 = new Array(25).fill(' ')
    const [items,setItems] = useState()
    const dataStruct = {
    home:{
        section1:{header:'',title:''},section2:{header:'',title:''}},
    att:{
        section1:{
            link1:'',
            link2:'',
            link3:'', 
            product0:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product1:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product2:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product3:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''}
        },
        section2:{
            link1:'',
            link2:'',
            link3:'', 
            product1:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product2:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product3:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
            product4:{header:'',title:'',dollars:'',cents:'',top:"",middle:'',bottom:''},
        }, 
        section3:{
            link1:'',
            link2:'',
            link3:'', 
        }
    }
        ,
    other:{
            product1:{video:'',text:""},
            product2:{image1:"",image2:'',header1:'',header2:'',title1:''},
            product3:{image1:"",image2:'',header1:'',header2:'',title1:''}}}
    const [data,setData] = useState({...dataStruct})
    const startSave = () =>{
        setTranslate('-2');setButtonText("Saving...");
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            background: 'rgba(0, 0, 0,0)',
            border:'1px solid white',
            color:"black",
            padding:'3px',
            input:{
              color:"black"
            }
         },
         input:{ 
           minHeight:"50px",
           color:'black' ,
           fontSize:"20px"
         }
   }));
   const classes = useStyles();

    useEffect(()=>{ 
             if(props.employees){
                setItems(props.employees.map((item,index)=>{  
                    return  <Employee index={item.id} employee={{...item}} /> 
                })  ) 
             }  
             if(!firstLoad && props.data && props.data.home){ 
                setData(props.data ? {...props.data} : null)
                setFirstLoad(true)
            } 
      
    },[props.user,props.data,props.employees])
    useEffect(()=>{
      
    },[data,props.data,items,translate,buttonText])
    const handleImageSelected = (e,type,number) =>{
        uploadData(e,type,number)
    }
    const uploadData = (e,type,number) => {
        setSaving(true)
        startSave()
        let file= e.target.files[0]; 
        let fileName= file.name; 
        var d = new Date();
        var n = parseInt(d.getMilliseconds()) +  Math.floor(Math.random() * Math.floor(10000));
        let storageRef=firebase.storage().ref(props.user.uid+'/'+fileName+n); 
        let uploadTask=storageRef.put(file); 
        uploadTask.on('state_changed', (snapshot) => {
                var progress=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
                setSaveProgress("Saving... " + parseInt(progress) +"% complete") 
                console.log("upload is " + progress +" done");
            }, (error) => {
                alert('error, try again. ' + error.message)
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downlaodURL) {
                    saveNewImage(downlaodURL,type,number)
                }) 
            });
    }
    const saveNewImage = (location,type,number) => {
            if(type === "fiber"){
                switch(parseInt(number)){
                    case 1:
                        setData({...data,att:{...data.att,section1:{...data.att.section1, link1:location}}})
                        savePagesIn({...data,att:{...data.att,section1:{...data.att.section1, link1:location}}})
                    break;
                    case 2:
                        setData({...data,att:{...data.att,section1:{...data.att.section1, link2:location}}}) 
                        savePagesIn({...data,att:{...data.att,section1:{...data.att.section1, link2:location}}})
                    break;
                    case 3: 
                        setData({...data,att:{...data.att,section1:{...data.att.section1, link3:location}}}) 
                        savePagesIn({...data,att:{...data.att,section1:{...data.att.section1, link3:location}}})
                    break;
                }
            } else 
            if(type === "tv"){
                switch(parseInt(number)){
                    case 1:
                        setData({...data,att:{...data.att,section2:{...data.att.section2, link1:location}}})
                        savePagesIn({...data,att:{...data.att,section2:{...data.att.section2, link1:location}}})
                    break;
                    case 2:
                        setData({...data,att:{...data.att,section2:{...data.att.section2, link2:location}}})
                        savePagesIn({...data,att:{...data.att,section2:{...data.att.section2, link2:location}}})
                    break;
                    case 3: 
                        setData({...data,att:{...data.att,section2:{...data.att.section2, link3:location}}})
                        savePagesIn({...data,att:{...data.att,section2:{...data.att.section2, link3:location}}})
                    break;
                }
            } else 
            if(type === "phone"){
                switch(parseInt(number)){
                    case 1:
                        setData({...data,att:{...data.att,section3:{...data.att.section3, link1:location}}})
                        savePagesIn({...data,att:{...data.att,section3:{...data.att.section3, link1:location}}})
                    break;
                    case 2:
                        setData({...data,att:{...data.att,section3:{...data.att.section3, link2:location}}})
                        savePagesIn({...data,att:{...data.att,section3:{...data.att.section3, link2:location}}})
                    break;
                    case 3: 
                        setData({...data,att:{...data.att,section3:{...data.att.section3, link3:location}}})
                        savePagesIn({...data,att:{...data.att,section3:{...data.att.section3, link3:location}}})
                    break;
                }
            }
            
            
    }
 const savePages=()=>{
     //save page date
     firebase.firestore().collection("data").doc("site").set({...data}).then(doc=>{
        setSaveProgress(0)
        setSaving(false)
        setButtonText("Save Successful")
        setTimeout(()=>{
            setTranslate('0')
            props.setUser(null)
            setButtonText("Save Pages")
        },750)
     })
 }
 const savePagesIn= (inn) =>{
    //save page date
    firebase.firestore().collection("data").doc("site").set({...inn}).then(doc=>{
       setSaveProgress(0)
       setSaving(false)
       setButtonText("Save Successful")
       setTimeout(()=>{
           setTranslate('0')
           props.setUser(null)
           setButtonText("Save Pages")
       },750)
    })
}
const backUp = () =>{
    if(props.employees){
        props.employees.map((item,index)=>{ 
            firebase.firestore().collection("employeesbackup").doc(item.id+"").set(item).then(item=>{
                console.log("set userhome")
            }).catch(e=>{
                console.log("backup failed userhome " + e.message)
            })
            
         })
    }
  
}
const loadEmp = () =>{
        firebase.firestore().collection("employeesbackup").get().then((querySnapshot) => {
            let setter = []
            querySnapshot.forEach((doc) => { 
                setter.push({...doc.data(),id:doc.id});
            });
            let sorted = setter.slice().sort((a, b) =>a.id - b.id); 
            if(sorted){
                sorted.map((item,index)=>{
                    console.log("settingbackup " )
                    firebase.firestore().collection("employees").doc(item.id+"").update({...item}).then(item=>{
                        console.log("loaded userhome")
                    }).catch(e=>{
                        console.log("load failed userhome " + e.message)
                    })
                    
                 })
            }
        }); 
}
    return (
        <div >
            <Button styler={{position:'absolute',left:0,bottom:props.deviceType === 'mobile' ? '12vh':'0vh',transform:'translateY('+translate+'vh)',textAlign:'flex-start',padding:'0px',margin:'0px',height:'50px',zIndex:'100',width:"100%",transition:'all 500ms ease-in-out'}} click={()=>{startSave();setTimeout(()=>{savePages()},500)}} >{buttonText}</Button>
            <CDiv  > 
                <Header>Site Manager</Header> 
                <Title style={{marginTop:'20px',fontWeight:'900',margin:'20px'}}>My Employees</Title>
                <Button click={()=>{backUp()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Back Up Employees</Button>
                <Button click={()=>{loadEmp()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Load Backed-Up Employees</Button>
              
                <div style={{display:"flex", maxHeight:'50vh',marginTop:'3vh',flexWrap:'wrap',overflow:'auto',justifyContent:'center',alignItems:'center',width:'100%'}}>
                {items ? items:<Loader loaded={items}/>} 
                </div> 
                <div style={{...Theme.boxContainer,border:'none'}}>
                <Title style={{marginTop:'20px',fontWeight:'900',margin:'20px'}}>Home Page</Title>
                <div style={{display:"flex",flexFlow:'column', minHeight:'20vh',marginTop:'3vh',flexWrap:'wrap',justifyContent:'center',alignItems:'center',width:'100%',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                <Title style={{textAlign:'start',width:"95%",fontWeight:'900',margin:'20px'}}>Section 1</Title>
                <TextField onChange={(e)=>{setData({...data,home:{...data.home,section1:{...data.home.section1,header:e.target.value}}})}} value={data && data.home && data.home.section1 ? data.home.section1.header:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header"   />    
                <TextField onChange={(e)=>{setData({...data,home:{...data.home,section1:{...data.home.section1,title:e.target.value}}})}}value={data && data.home && data.home.section1 ?  data.home.section1.title:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Title"   />    
                <Title style={{textAlign:'start',width:"95%",fontWeight:'900',margin:'20px'}}>Section 2</Title>
                <TextField onChange={(e)=>{setData({...data,home:{...data.home,section2:{...data.home.section2,header:e.target.value}}})}}value={data && data.home && data.home.section2 ? data.home.section2.header:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header"   /> 
                <TextField onChange={(e)=>{setData({...data,home:{...data.home,section2:{...data.home.section2,title:e.target.value}}})}}value={data && data.home && data.home.section2 ? data.home.section2.title:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Title"   /> 
                </div>
                
                </div>
                <div style={{...Theme.boxContainer,border:'none',justifiyContent:'center',alignItems:'center',padding:'0px'}}>
                <Title style={{marginTop:'40px',fontWeight:'900' }}>AT&T Page</Title>
                <div style={{display:"flex",flexFlow:'column', minHeight:'20vh',marginTop:'5px',flexWrap:'wrap',justifyContent:'center',alignItems:'center',width:'100%'}}>
                        <Title style={{textAlign:'start',width:"95%",fontWeight:'900',margin:'20px'}}>Fiber</Title>
                        {saving ? <div style={{display:'relative',width:'auto',height:'auto'}}>
                        <h3 style={{...Theme.text,zIndex:'10'}}>{saveProgress}</h3>
                        <Loader style={{...Theme.text,zIndex:'10'}}/></div> : null}
                        <div style={{display:"flex",flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <Button click={()=>{imgs.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 1</Button>
                            <Button click={()=>{saveNewImage('','fiber',1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 1</Button>
                            <input type='file' ref={imgs}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'fiber',1)}}></input>
                            <Button click={()=>{imgs1.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 2</Button>
                            <Button click={()=>{saveNewImage('','fiber',2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 2</Button>
                            <input type='file' ref={imgs1}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'fiber',2)}}></input>
                            <Button click={()=>{imgs2.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 3</Button>
                            <Button click={()=>{saveNewImage('','fiber',3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 3</Button>
                            <input type='file' ref={imgs2}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'fiber',3)}}></input> 
                        </div>
                        <div style={{width:"100%",display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                    <Title>0</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product0:{...data.att.section1.product0,dollars:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product0 ? data.att.section1.product0.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product0:{...data.att.section1.product0,cents:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product0 ? data.att.section1.product0.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product0:{...data.att.section1.product0,top:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product0 ? data.att.section1.product0.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product0:{...data.att.section1.product0,middle:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product0 ? data.att.section1.product0.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product0:{...data.att.section1.product0,bottom:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product0 ? data.att.section1.product0.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                    <Title>1</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product1:{...data.att.section1.product1,dollars:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product1 ? data.att.section1.product1.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product1:{...data.att.section1.product1,cents:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product1 ? data.att.section1.product1.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product1:{...data.att.section1.product1,top:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product1 ? data.att.section1.product1.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product1:{...data.att.section1.product1,middle:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product1 ? data.att.section1.product1.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product1:{...data.att.section1.product1,bottom:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product1 ? data.att.section1.product1.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                <Title>2</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product2:{...data.att.section1.product2,dollars:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product2 ? data.att.section1.product2.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product2:{...data.att.section1.product2,cents:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product2 ? data.att.section1.product2.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product2:{...data.att.section1.product2,top:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product2 ? data.att.section1.product2.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product2:{...data.att.section1.product2,middle:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product2 ? data.att.section1.product2.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product2:{...data.att.section1.product2,bottom:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product2 ? data.att.section1.product2.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                            
                                <Title>3</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product3:{...data.att.section1.product3,dollars:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product3 ? data.att.section1.product3.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product3:{...data.att.section1.product3,cents:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product3 ? data.att.section1.product3.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product3:{...data.att.section1.product3,top:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product3 ? data.att.section1.product3.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product3:{...data.att.section1.product3,middle:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product3 ? data.att.section1.product3.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section1:{...data.att.section1, product3:{...data.att.section1.product3,bottom:e.target.value}}}})}} value={data && data.att && data.att.section1 && data.att.section1.product3 ? data.att.section1.product3.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                        </div>  
                        <Title style={{textAlign:'start',width:"95%",margin:'20px',fontWeight:'900'}}>TV</Title>
                        {saving ? <div style={{display:'relative',width:'auto',height:'auto'}}>
                        <h3 style={{...Theme.text,zIndex:'10'}}>{saveProgress}</h3>
                        <Loader style={{...Theme.text,zIndex:'10'}}/></div> : null}
                        <div style={{display:"flex",flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <Button click={()=>{imgs3.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 1</Button>
                            <Button click={()=>{saveNewImage('','tv',1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 1</Button>
                            <input type='file' ref={imgs3}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'tv',1)}}></input>
                            <Button click={()=>{imgs4.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 2</Button>
                            <Button click={()=>{saveNewImage('','tv',2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 2</Button>
                            <input type='file' ref={imgs4}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'tv',2)}}></input>
                            <Button click={()=>{imgs5.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 3</Button>
                            <Button click={()=>{saveNewImage('','tv',3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 3</Button>
                            <input type='file' ref={imgs5}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'tv',3)}}></input> 
                        </div>
                        <div style={{width:"100%",display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                    <Title>1</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product1:{...data.att.section2.product1,dollars:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section1.product1 ? data.att.section2.product1.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product1:{...data.att.section2.product1,cents:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product1 ? data.att.section2.product1.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product1:{...data.att.section2.product1,top:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product1 ? data.att.section2.product1.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product1:{...data.att.section2.product1,middle:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product1 ? data.att.section2.product1.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product1:{...data.att.section2.product1,bottom:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product1 ? data.att.section2.product1.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                <Title>2</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product2:{...data.att.section2.product2,dollars:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product2 ? data.att.section2.product2.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product2:{...data.att.section2.product2,cents:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product2 ? data.att.section2.product2.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product2:{...data.att.section2.product2,top:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product2 ? data.att.section2.product2.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product2:{...data.att.section2.product2,middle:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product2 ? data.att.section2.product2.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product2:{...data.att.section2.product2,bottom:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product2 ? data.att.section2.product2.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                <Title>3</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product3:{...data.att.section2.product3,dollars:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product3 ? data.att.section2.product3.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product3:{...data.att.section2.product3,cents:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product3 ? data.att.section2.product3.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product3:{...data.att.section2.product3,top:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product3 ? data.att.section2.product3.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product3:{...data.att.section2.product3,middle:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product3 ? data.att.section2.product3.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product3:{...data.att.section2.product3,bottom:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product3 ? data.att.section2.product3.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                                <div style={{width:props.deviceType === 'mobile' ? "80%" : "40%",display:'flex',flexFlow:'column',flexWrap:'wrap',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                                <Title>4</Title>
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product4:{...data.att.section2.product4,dollars:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product4 ? data.att.section2.product4.dollars:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Dollars"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product4:{...data.att.section2.product4,cents:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product4 ? data.att.section2.product4.cents:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Cents"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product4:{...data.att.section2.product4,top:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product4 ? data.att.section2.product4.top:''}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Top"   />
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product4:{...data.att.section2.product4,middle:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product4 ? data.att.section2.product4.middle:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Middle"   /> 
                                    <TextField  onChange={(e)=>{setData({...data,att:{...data.att,section2:{...data.att.section2, product4:{...data.att.section2.product4,bottom:e.target.value}}}})}} value={data && data.att && data.att.section2 && data.att.section2.product4 ? data.att.section2.product4.bottom:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Bottom"   />
                                </div>
                        </div>
                        {/* insert three div buttons here  */}
                        <Title style={{textAlign:'start',width:"95%",fontWeight:'900',margin:'20px'}}>Phone</Title>
                        {saving ? <div style={{display:'relative',width:'auto',height:'auto'}}>
                        <h3 style={{...Theme.text,zIndex:'10'}}>{saveProgress}</h3>
                        <Loader style={{...Theme.text,zIndex:'10'}}/></div> : null}
                        <div style={{display:"flex",flexFlow:'row',flexWrap:'wrap',width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <Button click={()=>{imgs6.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 1</Button>
                            <Button click={()=>{saveNewImage('','phone',1)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 1</Button>
                            <input type='file' ref={imgs6}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'phone',1)}}></input>
                            <Button click={()=>{imgs7.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 2</Button>
                            <Button click={()=>{saveNewImage('','phone',2)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 2</Button>
                            <input type='file' ref={imgs7}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'phone',2)}}></input>
                            <Button click={()=>{imgs8.current.click()}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Change Flier 3</Button>
                            <Button click={()=>{saveNewImage('','phone',3)}} styler={{width:'auto',minWidth:'auto',height:'auto',minHeight:'auto',maxHeight:'auto',padding:'4px',margin:'5px'}}   >Clear Flier 3</Button>
                            <input type='file' ref={imgs8}  style={{display:'none'}}  onChange={e => {handleImageSelected(e,'phone',3)}}></input> 
                        </div>
                        <div style={{width:props.deviceType === 'mobile' ? "80vw" : '100%',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px'}}>
                            <TextField onChange={(e)=>{setData({...data,att:{...data.att,section3:{...data.att.section3,img:e.target.value}}})}} value={data && data.att && data.att.section3 ? data.att.section3.img:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Image Url"   />    
                            <TextField onChange={(e)=>{setData({...data,att:{...data.att,section3:{...data.att.section3,text:e.target.value}}})}}value={data && data.att && data.att.section3 ?  data.att.section3.text:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Text"   />     
                        </div>
                        {/* insert three div buttons here  */}
                </div> 
                </div>
                <div style={{...Theme.boxContainer,border:'none'}}>
                <Title style={{margin:'20px',fontWeight:'900'}}>Other Page</Title>
                <div style={{display:"flex",flexFlow:'column', minHeight:'20vh',marginTop:'3vh',flexWrap:'wrap',justifyContent:'center',alignItems:'center',width:'100%',boxShadow:'0px 0px 2px grey',margin:'5px',padding:'5px',marginBottom:'50px'}}>
                        <Title style={{textAlign:'start',width:"95%",fontWeight:'900'}}>Solar</Title>
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product1:{...data.other.product1, url:e.target.value}}})}} value={data && data.other && data.other.product1  ? data.other.product1.url:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="URL"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product1:{...data.other.product1, text:e.target.value}}})}} value={data && data.other && data.other.product1 ? data.other.product1.text:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Text"   />
                        <Title style={{textAlign:'start',width:"95%",fontWeight:'900'}}>Security</Title>
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product2:{...data.other.product2, header1:e.target.value}}})}} value={data && data.other && data.other.product2  ? data.other.product2.header1:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product2:{...data.other.product2, header2:e.target.value}}})}} value={data && data.other && data.other.product2 ? data.other.product2.header2:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header2"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product2:{...data.other.product2, title1:e.target.value}}})}} value={data && data.other && data.other.product2  ? data.other.product2.title1:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Title1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product2:{...data.other.product2, image1:e.target.value}}})}} value={data && data.other && data.other.product2 ? data.other.product2.image1:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Image1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product2:{...data.other.product2, image2:e.target.value}}})}} value={data && data.other && data.other.product2 ? data.other.product2.image2:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Image2"   />
                        <Title style={{textAlign:'start',width:"95%",fontWeight:'900'}}>Wellness</Title> 
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product3:{...data.other.product3, header1:e.target.value}}})}} value={data && data.other && data.other.product3  ? data.other.product3.header1:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product3:{...data.other.product3, header2:e.target.value}}})}} value={data && data.other && data.other.product3 ? data.other.product3.header2:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Header2"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product3:{...data.other.product3, title1:e.target.value}}})}} value={data && data.other && data.other.product3  ? data.other.product3.title1:''} InputProps={{ className: classes.input }}  className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Title1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product3:{...data.other.product3, image1:e.target.value}}})}} value={data && data.other && data.other.product3 ? data.other.product3.image1:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Image1"   />
                        <TextField  onChange={(e)=>{setData({...data,other:{...data.other,product3:{...data.other.product3, image2:e.target.value}}})}} value={data && data.other && data.other.product3 ? data.other.product3.image2:''} className={classes.root} style={{width:'100%',color:'white',marginBottom:"2vh"}} id="outlined-basic" label="Image2"   />
                </div>
                    {/* insert three div buttons here  */} 
                </div>
            </CDiv>
        </div>
            )
}


const mapStateTopProps = state =>{
    return { ...toolbarStateToProps(state)}
}
const mapDispatchToProps = dispatch =>{
    return { ...toolbarDispatchToProps(dispatch)}
}
export default connect(mapStateTopProps,mapDispatchToProps)(UserHome)

import React,{useEffect} from 'react'
import useTheme from '../Theme/useTheme'
import  { NavLink,useHistory} from 'react-router-dom';  
import classes from './Toolbar.module.css' 
import toolbarDispatchToProps from '../../Store/MapDispatchToProps/toolbarDispatchToProps'
import toolbarStateToProps from '../../Store/MapStateToProps/toolbarStateToProps' 
import { connect } from 'react-redux';
const Links = (props) => {
    let history = useHistory();
    const Theme = useTheme(props.scroll);
    useEffect(()=>{
        console.log(history.location.pathname)
    },[history.location.pathname,props.currentName, props.nameDisplay ]) 
    return ( 
             <NavLink onClick={()=>{props.click();props.setScroll(false);}} style={{...Theme.toolbarLinks,  color: props.currentName === props.nameDisplay ?'white' :  'whitesmoke',textDecoration: props.currentName === props.nameDisplay ?'underline' :  'none',textShadow: props.currentName === props.nameDisplay ?'0px 0px 3px black' :  '0px 0px 1px black' ,fontSize: props.currentName === props.nameDisplay ?'21px' :  '19px',marginBottom:'auto',marginTop:'auto',marginLeft:'5px',marginRight:'5px'}}    to={props.name} exact > {props.nameDisplay}</NavLink>
    )
}

const mapStateTopProps = state =>{
    return toolbarStateToProps(state)
}
const mapDispatchToProps = dispatch =>{
    return toolbarDispatchToProps(dispatch)
}
export default connect(mapStateTopProps,mapDispatchToProps)(Links) 

import React, { useEffect, useState,useRef } from 'react'
import Cropper from "react-cropper"; 
import Button from '../Button/Button'
import "cropperjs/dist/cropper.min.css" 
import {AiOutlineRotateRight,AiOutlineZoomIn,AiOutlineRotateLeft,AiOutlineZoomOut,AiOutlinePlusSquare} from 'react-icons/ai' 
const ImageCropper = (props) => { 
    const [showAdd,setShowAdd] = useState(false)
    const [croppedString,setCroppedString] = useState(null) //image destination  
    const img_preview = {
        width:'10vh',
        height:'10vh', 
        borderRadius:'8px',
        border:'thin solid black',
        margin:'5px',
        zIndex:'9'
    }
    
    const cropperRef = useRef(null);
    const onCrop = (event) => { 
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      setCroppedString(cropper.getCroppedCanvas(  {width: 0,
        height: 0,
        minWidth: 500,
        minHeight: 500,
        maxWidth: 500   ,
        aspectRatio:1,
        maxHeight: 500,
        
        fillColor: '#fff',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'}).toDataURL())
      setShowAdd(true) 
    };
    
    /* onCropComplete() will occur each time the user modifies the cropped area, 
    which isn't ideal. A better implementation would be getting the blob 
    only when the user hits the submit button, but this works for now  */
    const addToItem = ()=>{
        props.getBlob(croppedString)
        setCroppedString(null)
        setShowAdd(false)
        props.setInputImg('')
    }
    const cropper = <Cropper
    src={props.inputImg}
    style={{ minHeight: '10vh', width: "90%" }}
    // Cropper.js options
    initialAspectRatio={1}
    aspectRatio={1} 
    guides={true}
    crop={e=>onCrop(e)}
    ref={cropperRef} 
    />
   
    return (
        <div style={{marginTop:"0px",display:'flex',justifyContent:'center',alignItems:'center',flexFlow:'column',width:'100%'}} >
         {showAdd ?    <Button click={addToItem}  className="btn-success"><AiOutlinePlusSquare style={{fontSize:'25px'}}/> Add Cropped Image</Button>:null}
           <img src={croppedString} style={{...img_preview}}/> 
             <div style={{display:'flex',flexFlow:'row',justifyContent:'center',width:'100%',position:'relative'}}>
            
            <AiOutlineRotateLeft onClick={()=>{
                    const imageElement = cropperRef?.current;
                    const cropper = imageElement?.cropper;
                    cropper.rotate(-90)
             
                 
             }} style={{color:'black',backgroundColor:'white',zIndex:10,position:'relative',borderRadius:'16px',fontSize:'35px',margin:'10px'}}/>
             <AiOutlineRotateRight onClick={()=>{
                    const imageElement = cropperRef?.current;
                    const cropper = imageElement?.cropper;
                    cropper.rotate(90)
              
                 
             }} style={{color:'black',backgroundColor:'white',zIndex:10,position:'relative',borderRadius:'16px',fontSize:'35px',margin:'10px'}}/>
             
               
            </div>
            <div style={{overflow:'hidden',borderRadius:'8px'}}  >
                {cropper}
            </div>
            
          
        </div> 
    )
}

export default ImageCropper
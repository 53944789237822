import Types from '../Types'
const toolbarDispatchToProps = dispatch => { 
        return {
    setShowMenu:(show)=> dispatch({type:Types.setShowMenu,value:show}),
    setShowMenuManual:(boolVal)=> dispatch({type:Types.setShowMenuManual,value:boolVal}),
    setAuth:(auth)=>dispatch({type:Types.setAuth,value:auth}),
    setCurrentName:(name)=>dispatch({type:Types.setCurrentName,value:name}),
    setUser:(user)=>{dispatch({type:Types.setUser,value:user})}, 
    setToolbarDisplay:(display)=>{dispatch({type:Types.setToolbarDisplay,value:display})},  
    setShowModal:(show)=>{dispatch({type:Types.setShowModal,value:show})},
    setModalContent:(content)=>{dispatch({type:Types.setModalContent,value:content})},
    setScroll:(scroll)=>{dispatch({type:Types.setScroll,value:scroll})} ,
    setBills:(bills)=>{dispatch({type:Types.setBills,value:bills})} ,
    setWork:(work)=>{dispatch({type:Types.setWork,value:work})} ,
    setNavigation:(nav)=>{dispatch({type:Types.setNavigation,value:nav})},
    setScrollTop:(top)=>{dispatch({type:Types.setScrollTop,value:top})} ,
    setEmployees:(emp)=>{dispatch({type:Types.setEmployees,value:emp})} ,
    setDeviceType:(type)=>{dispatch({type:Types.setDeviceType,value:type})},
    setData:(data)=>{dispatch({type:Types.setData,value:data})} 
    }
}
 export default toolbarDispatchToProps;
 